@import "./_variables";
@import "./_utilities";
@import "./_fonts";
@import "./_functions";

html,
body,
.App,
#root {
    height: 100%;
    font-family: $font-primary;

    &.bg-grey {
        background-color: $grey-white;
    }
}

// FONTS
h1,
h2,
h3 {
    font-family: $font-semi-bold;
}

h1 {
    font-size: rem(30);
}

h2 {
    font-size: rem(22);
}

h3 {
    font-size: rem(15);
}

p,
span,
label {
    color: $main-texts;
    font-size: rem(13);
}

a,
p,
span,
label {
    font-family: $font-primary;

    &.text-bold {
        font-family: $font-semi-bold;
    }
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
}

//disable global focus style
*:focus {
    box-shadow: none !important;
    outline: 0;
}

//disable text decoration on links
a {
    font-size: rem(13);
    font-family: $font-primary;
    text-decoration: none;
    color: $main-texts;

    &:not(.btn):hover {
        color: $main-texts !important;
    }
}

//disable listes styles
ul,
ol {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}

/* Prevent blue background on autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
}

hr {
    border-bottom: 1px solid $light-grey;
}

.text-truncate {
    // line-height: 2;
}
