/**** Fonts ***/
$font-primary: "Poppins regular", sans-serif;
$font-light: "Poppins light", sans-serif;
$font-semi-bold: "Poppins semi-bold", sans-serif;
$font-medium: "Poppins medium", sans-serif;
$font-secondary: "Roboto regular", sans-serif;

/***+ Colors ***/
//to use
$blue-grey: #ced3de !default;
$grey-white: #f8f9fa !default;
$very-clear-grey: #f0f2f4 !default;
$border-grey: #dde1e4 !default;
$clear-blue-grey: #6b7897 !default;
$light-blue-grey: #f8f9fa !default;
$light-grey: #efefef !default;
$clear-grey: #e1e3ea !default;
$clearer-grey: #fcfbff !default;
$clear-text: #bfc7ce !default;
$main-texts: #333 !default;
$helper-background: #fafbfc;
// $primary: #47dc95 !default;
$primary: #47dc95 !default;
$white: #fff !default;
// $secondary: #fed340 !default;
$secondary: #285165 !default;
$green: #aeeacf !default;
$processing: #fcb922 !default;
$success: #7ed321 !default;
$error: #d0021b !default;

//to delete
$black: #000 !default;
$purple: $primary !important;
$semi-light-grey: #777777 !default;
$grey: #939faf !default;

// button mouse actions:
// $hover-main: linear-gradient(to top, #f50a6c, #fb3b60, #fd5857, #fc7051, #fa8650);
$hover-main: #2ea76d;
$active-main: #27744f;

/***** Sidebar *******/
$sidebar-width: 220px;

/***** Header ********/
$header-height: 100px;

/***** Table  ********/
$row-selected: rgba(#2196f3, 0.05);

// Breakpoints
$screen-xl: 1199px;
$screen-lg: 992px;
$screen-md: 768px;
$screen-sm: 641px;
$screen-mini: 380px;

/***** Shadow  ********/
$box-shadow: 0 8px 20px 2px rgba(black, 0.05);
