@import './variables.scss';

.header-logo,
.header-actions {
    display: flex;
    align-items: center;
    height: 100%;
}

.header-logo {
    justify-content: start;
    gap: 30px;
    padding: 20px;
    width: $sidebar-width;
    button {
        height: 20px;
        width: 30px;
        background-color: transparent;
        border: none;

        &::before {
            transition: all 0.2s ease-in-out;
            content: '';
            display: block;
            background: url('../../../../../static/icons/studio/icon_back.svg') no-repeat center;
            height: 100%;
            width: 100%;
            background-size: contain;
        }
        &:hover {
            transition: all 0.2s ease-in-out;
            filter: invert(79%) sepia(13%) saturate(1814%) hue-rotate(96deg) brightness(92%) contrast(94%);
        }
    }
}

.header-actions {
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 55px;
    gap: 10px;

    .header-actions__filename {
        flex-grow: 1;
    }

    .header-actions__buttons-container {
        gap: 20px;
        display: flex;
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 140px;
            border-radius: 8px;

            &:disabled {
                opacity: 0.4;
                pointer-events: none;
            }
        }

        &__button-save {
            background-color: $white;
            border: 1px solid $clear-grey;

            span {
                color: $primary;
            }
            &:hover {
                background: $primary;
                border: none;
                span {
                    color: $white;
                }
                &::before {
                    filter: invert(100%) sepia(98%) saturate(0%) hue-rotate(280deg) brightness(102%) contrast(102%);
                }
            }

            &::before {
                content: '';
                margin-top: 4px;
                display: block;
                background: url('../../../../../static/icons/studio/icon_save.svg') no-repeat center;
                height: 20px;
                width: 20px;
                background-size: contain;
                margin-right: 10px;
                filter: invert(79%) sepia(13%) saturate(1814%) hue-rotate(96deg) brightness(92%) contrast(94%);
            }
        }
        &__button-export {
            background-color: $primary;
            border: none;

            span {
                color: $white;
            }
            &:hover {
                background: $hover-main;
                border: none;
            }

            &::before {
                content: '';
                display: block;
                background: url('../../../../../static/icons/studio/icon_export.svg') no-repeat center;
                height: 20px;
                width: 20px;
                background-size: contain;
                margin-right: 10px;
                filter: invert(99%) sepia(5%) saturate(186%) hue-rotate(203deg) brightness(116%) contrast(100%);
            }
        }
    }
}
