@import "../../static/scss/variables";
@import "../../static/scss/functions";

.button {
    padding: 0 12px;
    border-radius: 8px;
    min-width: 140px;
    border: none;
    font-size: rem(13) !important;
    position: relative;

    &.with-loader {
        pointer-events: none;
        user-select: none;
        opacity: 0.7;
        &:before {
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url("../../static/icons/round-loader-animated.svg") no-repeat center;
            background-size: contain;
        }
    }

    &.bordered {
        border: 1px solid white;
    }

    &:disabled {
        box-shadow: none;
        opacity: 0.2;
        cursor: not-allowed;
    }

    &:focus {
        outline: 0;
        box-shadow: none;
    }

    &.btn-main {
        color: $white;
        height: 40px;
        line-height: calc(40px - 12px); // 12px is the padding
        text-align: center;
        background-color: $primary;
        border: 0; // Prevent pink border on top if background is a gradient
        transition: background 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover:not(:disabled) {
            background: $hover-main;
        }

        &:active:not(:disabled) {
            background: $active-main;
        }

        &:disabled {
            opacity: 1;
            color: $blue-grey;
            background-color: $grey-white;
            border: 1px solid $very-clear-grey;

            &.grey-dark {
                opacity: 0.5;
                background-color: $clear-blue-grey;
            }
        }

        &.task-list {
            gap: 10px;
        }

        &.add-detection {
            display: flex;
            align-items: center;

            &.disabled {
                opacity: 1;
                color: $blue-grey;
                background-color: $grey-white;
                border: 1px solid $very-clear-grey;
            }

            &::before {
                content: "";
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url("../../static/icons/studio/AdDetectionPlus.svg") no-repeat center;
                background-size: 16px;
                border-radius: 4px;
                margin-right: 10px;
                background-color: rgba($color: #fff, $alpha: 0.5);
            }

            &:not(:disabled):hover {
                background: $primary !important;

                &::before {
                    background: url("../../static/icons/studio/AdDetectionPlusColor.svg") no-repeat
                        center;
                    background-color: white;
                    box-shadow: 0px 2px 4px 0px rgba(216, 14, 99, 1);
                }
            }
        }
    }

    &.btn-main-inverted {
        color: $white;
        background: $hover-main;
        height: 40px;
        line-height: calc(40px - 12px); // 12px is the padding

        &:hover {
            background: none;
            background-color: $primary !important;
        }

        &:disabled {
            opacity: 1;
            background-color: $light-blue-grey;
        }
    }

    &.btn-disconnect {
        min-height: inherit;
        min-width: inherit;
        background: url(../../static/icons/icon_disconnect.svg) no-repeat center;

        &:hover {
            background: url(../../static/icons/icon_deconnexion-white.svg) no-repeat center;
            background-color: $primary;
            background-size: 21px;
        }

        margin: auto;
        border-radius: 50% !important;
        height: 60px !important;
        width: 60px !important;
        padding: 0;
        background-size: 20px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
    }

    &.btn-close {
        min-height: inherit;
        min-width: inherit;
        background: url(../../static/icons/icon_close.svg) no-repeat center;
        transition: all 300ms ease;

        &:hover {
            background: url(../../static/icons/icon_close-white.svg) no-repeat center;
            background-color: $primary;
            background-size: 21px;
        }

        margin: auto;
        height: 20px !important;
        width: 30px !important;
        padding: 0;
        background-size: 20px;
        transform: translate(10px, -10px);

        @media screen and (min-width: $screen-lg) {
            height: 40px !important;
            width: 40px !important;
            border: 1px solid $primary;
            border-radius: 50% !important;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            transform: none;
        }
    }

    &.btn-white-main {
        height: 40px;
        line-height: calc(40px - 12px); // 12px is the padding
        color: $primary;
        background-color: $white;
        border: 1px solid $light-grey;
        box-shadow: none !important;

        @media screen and (min-width: $screen-lg) {
            &:hover {
                border: 1px solid $white !important;
                color: $white !important;
                background: $hover-main;
            }
        }
    }

    &.collapse-btn {
        background: url("../../static/icons/right-arrow.svg") no-repeat center;
        height: 25px;
        width: 25px;
        min-width: 0;
        padding: 0 !important;
        box-shadow: none !important;
        transform: rotate(90deg);
        transition: all 300ms linear;

        &.active {
            transform: rotate(270deg);
            background: url("../../static/icons/right-arrow-active.svg") no-repeat center;
        }
    }

    &.btn-collapse {
        display: block;
        min-width: auto !important;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 0;
        transition: background 0.1s ease;

        @media screen and (min-width: $screen-lg) {
            display: none;
        }

        svg {
            width: 30px;
            height: 30px;
            transform: scale(1);
            transition: transform 0.1s ease;
        }

        &.expanded {
            background: $primary;

            svg {
                transform: scale(-1);
            }
        }

        &.btn-collapse-card {
            position: absolute;
            top: 35px;
            right: 15px;
            background: white;
            border: 1px solid $very-clear-grey;

            svg {
                width: 28px;
                height: 28px;
            }

            &[aria-expanded="true"] {
                background: $primary;
                border: 1px solid transparent;

                svg {
                    transform: scale(-1) translateY(1px);
                    fill: white;
                }
            }
        }
    }

    &.btn-filter-mobile {
        width: 40px;
        height: 40px;
        min-width: inherit !important;
        padding: 0;
        border-radius: 50%;
        background: $grey-white;
        transition: background 0.3s ease;

        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
            background: $primary;

            svg {
                fill: white;
            }
        }

        &:disabled {
            opacity: 1;
            background: $grey-white;
        }

        @media screen and (min-width: $screen-lg) {
            display: none;
        }
    }

    &.tab-button {
        box-shadow: none !important;
        border-radius: inherit !important;
        height: 50px !important;
        background-color: $white !important;
        min-width: 120px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        font-size: rem(16) !important;
        color: $clear-blue-grey;
        border-bottom: 2px solid $light-blue-grey;

        &.active {
            font-size: rem(16) !important;
            color: $primary;
            font-family: $font-semi-bold !important;
            border-bottom: 2px solid $primary;
        }

        @media screen and (min-width: $screen-lg) {
            height: 50px !important;
            min-width: 180px !important;
            font-size: rem(18) !important;

            &:hover {
                color: $primary;
                font-size: rem(22) !important;
                font-family: $font-semi-bold;
                border-bottom: 3px solid $primary;
            }

            &.active {
                font-size: rem(22) !important;
                border-bottom: 3px solid $primary;
            }
        }

        &.payment {
            background-color: $grey-white !important;
        }
    }

    &.btn-account {
        min-width: inherit;
        min-height: inherit;
        height: 45px;
        width: 45px;
        display: flex!important;
        justify-content: center;
        align-items: center;
        padding: 0;
        border-radius: 50%;
        background-color: $green;
        color: $white;
        border: 1px solid $light-grey;
        font-family: $font-semi-bold;

        @media screen and (min-width: $screen-lg) {
            height: 60px;
            width: 60px;
        }

        &:hover {
            background-color: #33e394;
        }

        &.with-loader {
            &:before {
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.btn-deco {
        font-size: rem(15);
        line-height: rem(53);
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $clear-grey;
        &:before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 16px;
            background: url("../../static/icons/icon_disconnect.svg") no-repeat center;
            background-size: contain;
        }
    }

    &.btn-menu {
        min-width: inherit;
        border-radius: 0;
        padding: 0;
        background: 0;

        width: 45px;
        height: 45px;
        text-align: center;
        position: relative;

        &:before {
            content: "";
            width: 26px;
            height: 26px;
            display: inline-block;
            background: url("../../static/icons/icon-menu.svg") no-repeat center center;
            background-size: contain;
        }

        &.active:before {
            background: url("../../static/icons/icon-menu-close.svg") no-repeat center center;
            background-size: contain;
        }
    }

    &.btn-task {
        position: relative;
        padding: 0 45px 0 10px;
        height: 55px;
        width: 180px;
        min-width: inherit;
        border-radius: 60px;
        background: $primary;
        text-align: center;
        display: block;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
        transition: width 0.25s ease;

        @media screen and (min-width: $screen-lg) {
            display: none;
        }

        &.active,
        &.scroll-down.active {
            width: 170px;
            background: $active-main;

            .button-text {
                opacity: 1;
                white-space: nowrap;
            }

            .icon {
                background: white;

                svg {
                    fill: $active-main;
                }
            }
        }

        &.white {
            background: white;
            width: auto;
            min-width: auto;
            line-height: 55px;
            padding: 0 50px 0 10px;

            .icon {
                background: $primary;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }

            .button-text {
                color: $primary;
            }
        }

        .button-text {
            font-size: rem(13);
            color: white;
            transition: opacity 0.25s ease 0.25s;
        }

        &.scroll-down {
            width: 55px;

            .button-text {
                opacity: 0;
                transition: opacity 0.1s ease;
            }

            .icon {
                background: $primary;

                svg {
                    fill: white;
                }
            }
        }

        .icon {
            width: 35px;
            height: 35px;
            position: absolute;
            top: 10px;
            right: 10px;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.btn-squarred {
        background: $hover-main;
        font-size: rem(15);
        color: white;
        border-radius: 7px;
        line-height: rem(23);
        height: 65px;
        min-width: 200px;
        display: flex;
        align-items: center;

        &:hover {
            background: none;
            background-color: $primary;
        }

        &::before {
            content: "";
            left: 50px;
            background-size: contain;
            width: 35px;
            height: 35px;
            display: block;
            margin-right: 20px;
        }

        &.custom-disabled {
            opacity: 0.2;
            border-radius: 7px !important;
        }

        &[class*="anonymization"] {
            &::before {
                background: transparent url("../../static/icons/tasks/anonymization.svg") no-repeat
                    center;
            }
        }
    }

    &.btn-desktop-task {
        font-size: rem(12);
        color: white;
        border-radius: rem(10);
        line-height: rem(15);
        height: 55px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        text-align: left;
        background: linear-gradient(to top, $primary, $primary);
        font-family: $font-medium !important;
        transition: background-color ease-in-out 0.25s;
        min-width: 150px;

        &::before {
            content: "";
            left: 50px;
            background-size: contain;
            width: 35px;
            height: 35px;
            display: block;
            margin-right: 10px;
        }

        &:hover:not(:disabled) {
            background: $hover-main;
        }

        &:active:not(:disabled) {
            background: $hover-main;
        }

        &[class*="anonymization"] {
            &::before {
                background: transparent url("../../static/icons/tasks/anonymization.svg") no-repeat
                    center;
                background-size: contain;
            }
        }
    }

    &.btn-secondary {
        height: 40px;
        color: white;
        background-color: $secondary;
        border: 0;
        transition: background-color 0.3s ease-in-out;

        display: flex;
        align-items: center;
        gap: 0 rem(4);
        justify-content: center;

        &:hover:not(:disabled) {
            background: darken($secondary, 5%);
        }

        &:disabled,
        &.disabled {
            opacity: 1;
            color: $blue-grey;
            background-color: $grey-white;
            border: 1px solid $very-clear-grey;

            &.grey-dark {
                opacity: 0.5;
                background-color: $clear-blue-grey;
            }

            svg {
                fill: $clear-blue-grey;
            }
        }
    }

    &.btn-cancel-dark {
        height: 40px;
        color: $secondary;
        background-color: transparent;
        border: 1px solid $secondary;
        transition: background-color 0.3s ease-in-out;

        &:hover:not(:disabled) {
            background: darken($secondary, 5%);
            color: white;
        }

        &:disabled,
        &.disabled {
            opacity: 1;
            color: $blue-grey;
            background-color: $grey-white;
            border: 1px solid $very-clear-grey;

            &.grey-dark {
                opacity: 0.5;
                background-color: $clear-blue-grey;
            }
        }
    }

    &.btn-tasks {
        min-width: 130px;
        height: 45px;
        color: white;
        background: $secondary;
        border-radius: 8px;
        padding: 0 20px;
        box-shadow: none;
        transition: background 0.25s ease;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 rem(8);

        &:hover {
            background: darken($secondary, 5%) !important;
        }
    }

    &.btn-main-rounded {
        color: $white;
        height: 40px;
        line-height: calc(40px - 12px); // 12px is the padding
        text-align: center;
        background-color: $primary;
        border: 0;
        border-radius: 20px; // Half of the height for a fully rounded look
        transition: background 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover:not(:disabled) {
            background: $hover-main;
        }

        &:active:not(:disabled) {
            background: $active-main;
        }

        &:disabled {
            opacity: 1;
            color: $blue-grey;
            background-color: $grey-white;
            border: 1px solid $very-clear-grey;

            &.grey-dark {
                opacity: 0.5;
                background-color: $clear-blue-grey;
            }
        }
    }
}

.modal {
    .btn {
        padding: 7px 12px 7px 12px;
        border-radius: rem(10px) !important;
        min-width: 140px;
        border: none;
        font-size: rem(13) !important;
        margin: 0 4px!important;

        &.bordered {
            border: 1px solid white;
        }

        &:disabled {
            box-shadow: none;
            opacity: 0.2;
        }

        &:focus {
            outline: 0;
            box-shadow: none;
        }

        &.btn-main {
            color: $white;
            height: 40px;
            line-height: calc(40px - 12px); // 12px is the padding
            text-align: center;
            background-color: $primary;
            border: 0; // Prevent pink border on top if background is a gradient
            transition: background 0.3s ease-in-out;
            &:hover:not(:disabled) {
                background: $hover-main;
            }

            &:active:not(:disabled) {
                background: $active-main;
            }

            &:disabled {
                opacity: 1;
                color: $blue-grey;
                background-color: $grey-white;
                border: 1px solid $very-clear-grey;

                &.grey-dark {
                    opacity: 0.5;
                    background-color: $clear-blue-grey;
                }
            }

            &.add-detection {
                display: flex;
                align-items: center;

                &.disabled {
                    opacity: 1;
                    color: $blue-grey;
                    background-color: $grey-white;
                    border: 1px solid $very-clear-grey;
                }

                &::before {
                    content: "";
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    background: url("../../static/icons/studio/AdDetectionPlus.svg") no-repeat
                        center;
                    background-size: 16px;
                    border-radius: 4px;
                    margin-right: 10px;
                    background-color: rgba($color: #fff, $alpha: 0.5);
                }

                &:not(:disabled):hover {
                    background: $primary !important;

                    &::before {
                        background: url("../../static/icons/studio/AdDetectionPlusColor.svg")
                            no-repeat center;
                        background-color: white;
                        box-shadow: 0px 2px 4px 0px rgba(216, 14, 99, 1);
                    }
                }
            }
        }

        &.btn-main-rounded {
            color: $white;
            height: 40px;
            line-height: calc(40px - 12px); // 12px is the padding
            text-align: center;
            background-color: $primary;
            border: 0;
            border-radius: 20px; // Half of the height for a fully rounded look
            transition: background 0.3s ease-in-out;

            &:hover:not(:disabled) {
                background: $hover-main;
            }

            &:active:not(:disabled) {
                background: $active-main;
            }

            &:disabled {
                opacity: 1;
                color: $blue-grey;
                background-color: $grey-white;
                border: 1px solid $very-clear-grey;

                &.grey-dark {
                    opacity: 0.5;
                    background-color: $clear-blue-grey;
                }
            }
        }

        &.btn-main-inverted {
            color: $white;
            background: $hover-main;
            height: 40px;
            line-height: calc(40px - 12px); // 12px is the padding

            &:hover {
                background: none;
                background-color: $primary !important;
            }

            &:disabled {
                opacity: 1;
                background-color: $light-blue-grey;
            }
        }

        &.btn-disconnect {
            min-height: inherit;
            min-width: inherit;
            background: url(../../static/icons/icon_disconnect.svg) no-repeat center;

            &:hover {
                background: url(../../static/icons/icon_deconnexion-white.svg) no-repeat center;
                background-color: $primary;
                background-size: 21px;
            }

            margin: auto;
            border-radius: 50% !important;
            height: 60px !important;
            width: 60px !important;
            padding: 0;
            background-size: 20px;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
        }

        &.btn-close {
            min-height: inherit;
            min-width: inherit;
            background: url(../../static/icons/icon_close.svg) no-repeat center;
            transition: all 300ms ease;

            &:hover {
                background: url(../../static/icons/icon_close-white.svg) no-repeat center;
                background-color: $primary;
                background-size: 21px;
            }

            margin: auto;
            height: 20px !important;
            width: 30px !important;
            padding: 0;
            background-size: 20px;
            transform: translate(10px, -10px);

            @media screen and (min-width: $screen-lg) {
                height: 40px !important;
                width: 40px !important;
                border: 1px solid $primary;
                border-radius: 50% !important;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
                transform: none;
            }
        }

        &.btn-white-main {
            height: 40px;
            line-height: calc(40px - 12px); // 12px is the padding
            color: $primary;
            background-color: $white;
            border: 1px solid $light-grey;
            text-decoration: none;
            box-shadow: none !important;

            @media screen and (min-width: $screen-lg) {
                &:hover {
                    border: 1px solid $white !important;
                    color: $white !important;
                    background: $hover-main;
                }
            }
        }

        &.collapse-btn {
            background: url("../../static/icons/right-arrow.svg") no-repeat center;
            height: 25px;
            width: 25px;
            min-width: 0;
            padding: 0 !important;
            box-shadow: none !important;
            transform: rotate(90deg);
            transition: all 300ms linear;

            &.active {
                transform: rotate(270deg);
                background: url("../../static/icons/right-arrow-active.svg") no-repeat center;
            }
        }

        &.btn-collapse {
            display: block;
            min-width: auto !important;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            padding: 0;
            transition: background 0.1s ease;

            @media screen and (min-width: $screen-lg) {
                display: none;
            }

            svg {
                width: 30px;
                height: 30px;
                transform: scale(1);
                transition: transform 0.1s ease;
            }

            &.expanded {
                background: $primary;

                svg {
                    transform: scale(-1);
                }
            }

            &.btn-collapse-card {
                position: absolute;
                top: 35px;
                right: 15px;
                background: white;
                border: 1px solid $very-clear-grey;

                svg {
                    width: 28px;
                    height: 28px;
                }

                &[aria-expanded="true"] {
                    background: $primary;
                    border: 1px solid transparent;

                    svg {
                        transform: scale(-1) translateY(1px);
                        fill: white;
                    }
                }
            }
        }

        &.btn-filter-mobile {
            width: 40px;
            height: 40px;
            min-width: inherit !important;
            padding: 0;
            border-radius: 50%;
            background: $grey-white;
            transition: background 0.3s ease;

            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
                background: $primary;

                svg {
                    fill: white;
                }
            }

            &:disabled {
                opacity: 1;
                background: $grey-white;
            }

            @media screen and (min-width: $screen-lg) {
                display: none;
            }
        }

        &.tab-button {
            box-shadow: none !important;
            border-radius: inherit !important;
            height: 50px !important;
            background-color: $white !important;
            min-width: 120px !important;
            width: 100%;
            padding-left: 0 !important;
            padding-right: 0 !important;
            font-size: rem(16) !important;
            color: $clear-blue-grey;
            border-bottom: 2px solid $light-blue-grey;

            &.active {
                font-size: rem(16) !important;
                color: $primary;
                font-family: $font-semi-bold !important;
                border-bottom: 2px solid $primary;
            }

            @media screen and (min-width: $screen-lg) {
                height: 50px !important;
                min-width: 150px !important;
                font-size: rem(18) !important;

                &:hover {
                    color: $primary;
                    font-size: rem(22) !important;
                    font-family: $font-semi-bold;
                    border-bottom: 3px solid $primary;
                }

                &.active {
                    font-size: rem(22) !important;
                    border-bottom: 3px solid $primary;
                }
            }

            &.payment {
                background-color: $grey-white !important;
            }
        }

        &.btn-account {
            min-width: inherit;
            min-height: inherit;
            height: 45px;
            width: 45px;
            border-radius: 50%;
            background-color: $green;
            color: $white;
            border: 1px solid $light-grey;
            font-family: $font-semi-bold;

            @media screen and (min-width: $screen-lg) {
                height: 60px;
                width: 60px;
            }

            &:hover {
                background-color: #33e394;
            }
        }

        &.btn-deco {
            display: block;
            width: 100%;
            font-size: rem(15);
            line-height: rem(53);
            color: $clear-blue-grey;
            background: rgba($very-clear-grey, 0.56);
            border-radius: 5px;

            &:before {
                content: "";
                display: inline-block;
                width: 14px;
                height: 16px;
                background: url("../../static/icons/icon_disconnect.svg") no-repeat center;
                background-size: contain;
                margin-right: 5px;
                transform: translateY(2px);
            }
        }

        &.btn-menu {
            min-width: inherit;
            border-radius: 0;
            padding: 0;
            background: 0;

            width: 45px;
            height: 45px;
            text-align: center;
            position: relative;

            &:before {
                content: "";
                width: 26px;
                height: 26px;
                display: inline-block;
                background: url("../../static/icons/icon-menu.svg") no-repeat center center;
                background-size: contain;
            }

            &.active:before {
                background: url("../../static/icons/icon-menu-close.svg") no-repeat center center;
                background-size: contain;
            }
        }

        &.btn-task {
            position: relative;
            padding: 0 45px 0 10px;
            height: 55px;
            width: 180px;
            min-width: inherit;
            border-radius: 60px;
            background: $primary;
            text-align: center;
            display: block;
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
            transition: width 0.25s ease;

            @media screen and (min-width: $screen-lg) {
                display: none;
            }

            &.active,
            &.scroll-down.active {
                width: 170px;
                background: $active-main;

                .button-text {
                    opacity: 1;
                    white-space: nowrap;
                }

                .icon {
                    background: white;

                    svg {
                        fill: $active-main;
                    }
                }
            }

            &.white {
                background: white;
                width: auto;
                min-width: auto;
                line-height: 55px;
                padding: 0 50px 0 10px;

                .icon {
                    background: $primary;

                    svg {
                        width: 25px;
                        height: 25px;
                    }
                }

                .button-text {
                    color: $primary;
                }
            }

            .button-text {
                font-size: rem(13);
                color: white;
                transition: opacity 0.25s ease 0.25s;
            }

            &.scroll-down {
                width: 55px;

                .button-text {
                    opacity: 0;
                    transition: opacity 0.1s ease;
                }

                .icon {
                    background: $primary;

                    svg {
                        fill: white;
                    }
                }
            }

            .icon {
                width: 35px;
                height: 35px;
                position: absolute;
                top: 10px;
                right: 10px;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.btn-squarred {
            background: $hover-main;
            font-size: rem(15);
            color: white;
            border-radius: 7px;
            line-height: rem(23);
            height: 65px;
            min-width: 200px;
            display: flex;
            align-items: center;

            &:hover {
                background: none;
                background-color: $primary;
            }

            &::before {
                content: "";
                left: 50px;
                background-size: contain;
                width: 35px;
                height: 35px;
                display: block;
                margin-right: 20px;
            }

            &.custom-disabled {
                opacity: 0.2;
                border-radius: 7px !important;
            }

            &[class*="anonymization"] {
                &::before {
                    background: transparent url("../../static/icons/tasks/anonymization.svg")
                        no-repeat center;
                }
            }
        }

        &.btn-desktop-task {
            font-size: rem(12);
            color: white;
            border-radius: 10px;
            line-height: rem(15);
            height: 55px;
            padding-right: 20px;
            display: flex;
            align-items: center;
            text-align: left;
            background: linear-gradient(to top, $primary, $primary);
            font-family: $font-medium !important;
            transition: background-color ease-in-out 0.25s;
            min-width: 150px;

            &::before {
                content: "";
                left: 50px;
                background-size: contain;
                width: 35px;
                height: 35px;
                display: block;
                margin-right: 10px;
            }

            &:hover:not(:disabled) {
                background: $hover-main;
            }

            &:active:not(:disabled) {
                background: $hover-main;
            }

            &[class*="anonymization"] {
                &::before {
                    background: transparent url("../../static/icons/tasks/anonymization.svg")
                        no-repeat center;
                    background-size: contain;
                }
            }
        }

        &.btn-tasks {
            min-width: 130px;
            height: 45px;
            color: white;
            background: $secondary;
            border-radius: 8px;
            padding: 0 20px;
            box-shadow: none;
            transition: background 0.25s ease;

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 rem(8);

            &:hover {
                background: darken($secondary, 5%) !important;
            }
        }
    }

    .modal {
        .btn {
            padding: 7px 12px 7px 12px;
            border-radius: rem(10px) !important;
            min-width: 140px;
            border: none;
            font-size: rem(13) !important;
            color: $white;

            &.btn-main {
                color: $white;
                background-color: $primary;
                border-color: inherit !important;
                box-shadow: none !important;

                &:hover {
                    background: $hover-main;
                    color: $white !important;
                }

                &:disabled {
                    opacity: 1;
                    background: none;
                    background-color: $light-blue-grey;
                }
            }

            &.btn-white-main {
                text-decoration: none !important;
                color: $primary;
                background-color: $white;
                border: 1px solid $light-grey;

                &:hover {
                    color: $primary !important;
                }
            }
        }
    }

    // Button-grp
    .button-grp {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;

        &.justify-start {
            justify-content: flex-start;
        }

        &.justify-end {
            justify-content: flex-end;
        }

        &.justify-center {
            justify-content: center;
        }

        &.justify-between {
            justify-content: space-between;
        }

        &.justify-around {
            justify-content: space-around;
        }

        &.justify-evenly {
            justify-content: space-evenly;
        }
    }
}
