@import '../../../static/scss/variables';
@import "../../../static/scss/functions";

.modal-calendar-container {
    font-size: rem(13);

    > div {
        height: 100%;
    }

    .calendar-header {
        height: 80px;
        background-color: $light-blue-grey;
        border-radius: rem(10);
        color: $clear-blue-grey;
        padding: 0px 40px;
        margin-bottom: 2%;
        user-select: none;

        .arrow-year {
            cursor: pointer;
            &.arrow-reversed {
                transform: scaleX(-1);
            }
        }
    }

    .total-conso {
        height: 60px;
        background-color: $primary;
        border-radius: rem(10);
        padding: 0px 20% 0px 20%;
        margin-bottom: 2%;
        label,
        span {
            color: $white !important;
        }
        label {
            font-family: $font-medium;
        }
        span {
            font-family: $font-primary;
        }
    }

    .calendar-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .month-container {
            padding: 10px;
            width: 25%;
            min-height: 180px;
            .inner-month {
                border: 2px solid $light-blue-grey;
                border-radius: rem(10);
                padding: 4% 10%;
                color: $clear-blue-grey;
                font-family: $font-medium;
                .inner-data {
                    label,
                    span {
                        color: $clear-blue-grey;
                    }

                    label {
                        font-family: $font-medium;
                    }
                    span {
                        font-family: $font-primary;
                    }
                    margin-bottom: 4%;
                }
                &.active {
                    background: $primary;
                    color: $white !important;
                    border: none !important;
                    label,
                    span {
                        color: $white !important;
                    }
                    label {
                        font-family: $font-medium;
                    }
                }
            }
        }
    }

    button[type="submit"] {
        text-transform: capitalize;
    }
}
