.drag-n-drop__input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.drag-n-drop__label {
    display: block;
    width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: stretch;
    height: -moz-available;
}
