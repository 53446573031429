@import "../../../static/scss/_variables";
@import "../../../static/scss/functions";

#status-table {
    transition: all ease-in-out 0.5s;
    user-select: none;
    border: none;

    &:after {
        display: none;
    }

    &:hover {
        background: none;
    }

    td {
        text-align: center;
        border: none;
        width: 100%;
    }

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
    }

    svg {
        margin-bottom: 1rem;
    }

    h2 {
        font-family: $font-primary;
        font-size: rem(24);
        font-weight: 600;
        line-height: rem(36);
        text-align: center;
        color: $blue-grey;
        margin-bottom: 0;
    }

    p {
        font-family: $font-primary;
        font-size: rem(20);
        font-weight: 600;
        line-height: rem(28);
        text-align: center;
        color: $blue-grey;
        margin-bottom: 0;
    }
}