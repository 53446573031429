@import "../../../static/scss/variables";
@import "../../../static/scss/functions";

/*
    MENU MOBILE
*/

.menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: white;
    display: none;
    flex-direction: column;
    z-index: 1001;

    &.show {
        display: flex;
    }

    // Prevent toggle mistake on desktop
    @media screen and (min-width: $screen-lg) {
        display: none !important;
    }

    &-header {
        height: 70px;
        padding: 0 15px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            height: 40px;
        }
    }

    &-body {
        padding: 10px 15px 15px 15px;

        ul {
            width: 100%;
            padding: 0;
            margin: 0;

            li {
                position: relative;
            }

            a {
                display: block;
                font-size: rem(20);
                line-height: rem(66);
                font-family: $font-medium;
                color: $main-texts;
                padding: 0 0 0 65px;

                &.active {
                    color: $primary;
                    background: #fafbfc;
                    border-radius: 15px;
                }

                &:before {
                    width: 24px;
                    height: 24px;
                    left: 30px;
                }
            }
        }
    }

    .trial {
        width: 100%;
        margin-top: 60px;
        border: 2px solid $grey-white;
        border-radius: 10px;
        padding: 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0 10px;

        &-text {
            flex-grow: 1;
            text-align: center;

            .title,
            .data {
                color: $clear-blue-grey;
                margin: 0;
            }

            .title {
                font-family: $font-medium;
            }

            .data {
                font-family: $font-light;
            }
        }

        &.trial-error {
            background: rgba($error, 0.05);
            border: 0;

            .trial-text {
                color: $error;
                font-size: rem(13);
            }
        }
    }

    &-footer {
        width: calc(100% - 30px);
        margin: auto auto 25px auto;
        padding: 0 15px;

        span {
            color: $clear-blue-grey;
            &.name {
                font-family: $font-semi-bold;
            }
        }

        &-account-badge {
            height: 45px;
            width: 45px;
            flex-shrink: 0;
            border-radius: 50%;
            background-color: $green;
            color: $white;
            border: 1px solid $light-grey;
            font-family: $font-semi-bold;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .copyright {
            text-align: center;
            color: rgba($clear-blue-grey, 0.3);
            margin: 30px 0 0;
        }
    }
}
