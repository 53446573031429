@import './variables.scss';

.media-controls {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    $icon-cursor: url('data:image/svg+xml,<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9999 7.27011C15.9998 7.7415 15.8333 8.19773 15.5297 8.55831L9.52986 15.6833C8.73074 16.6323 7.26937 16.6323 6.47022 15.6833L0.470072 8.55834C0.166404 8.19775 -0.000124144 7.74147 -0.000123469 7.27005L-0.000124186 1.00012C-0.000124165 0.447836 0.447588 0.000122416 0.99987 0.000119676L15 4.98388e-05C15.5523 4.73943e-05 16 0.447779 16 1.00008L15.9999 7.27011Z" fill="%2347dc95"/></svg>');
    $icon-cursor-hover: url('data:image/svg+xml,<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9999 7.27011C15.9998 7.7415 15.8333 8.19773 15.5297 8.55831L9.52986 15.6833C8.73074 16.6323 7.26937 16.6323 6.47022 15.6833L0.470072 8.55834C0.166404 8.19775 -0.000124144 7.74147 -0.000123469 7.27005L-0.000124186 1.00012C-0.000124165 0.447836 0.447588 0.000122416 0.99987 0.000119676L15 4.98388e-05C15.5523 4.73943e-05 16 0.447779 16 1.00008L15.9999 7.27011Z" fill="%232ea76d"/></svg>');

    &__timeline {
        gap: 10px;
        display: flex;
        flex-direction: column;

        &__ruler {
            display: flex;
            padding-left: 20px;
            padding-right: 20px;
            height: 25px;
            width: 100%;
            border-bottom: 2px solid $section-border-color;
            padding-top: 6px;
            padding-bottom: 6px;
            gap: 10px;
            &__dash {
                height: 100%;
                width: 2px;
                background-color: $section-border-color;
            }
        }

        &__sliders {
            position: relative;
            width: 100%;
            height: 10px;

            &__progress-bar {
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                padding-left: 20px;
                padding-right: 20px;

                &__range-buffered {
                    transition: width 0.5s ease-in-out;
                    position: absolute;
                    background-color: #d1d7e480;
                    border-radius: 5px;
                    height: 6px;
                    z-index: 1;
                }

                &__range-base {
                    position: absolute;
                    background-color: $light-blue-grey;
                    width: calc(100% - 40px);
                    border-radius: 5px;
                    height: 6px;
                    z-index: 0;
                }

                & input[type='range'] {
                    z-index: 2;
                    -webkit-appearance: none;
                    appearance: none;
                    cursor: pointer;
                    width: 100%;
                    height: 6px;
                    background: transparent;
                    border-radius: 5px;
                    background-image: linear-gradient(#47dc95, #47dc95);
                    background-repeat: no-repeat;
                }

                & input[type='range']::-webkit-slider-thumb {
                    transition: all 0.2s ease-in-out;
                    position: relative;
                    appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                    height: 50px;
                    width: 14px;
                    transform: translateY(-60%);
                    background-image: url('../../../../../static/icons/studio/icon_timeline-cursor-move.svg'),
                        url('../../../../../static/icons/studio/icon_timeline-circle.svg'), $icon-cursor,
                        linear-gradient(to right, #47dc95, #47dc95);
                    background-repeat: no-repeat, no-repeat, no-repeat, repeat-y;
                    background-position: center 3px, center 29px, center 0px, center 3px;
                    background-size: 4px, contain, contain, 2px 100%;

                    &:hover {
                        transition: all 0.2s ease-in-out;
                        background-image: url('../../../../../static/icons/studio/icon_timeline-cursor-move.svg'),
                            url('../../../../../static/icons/studio/icon_timeline-circle.svg'), $icon-cursor-hover,
                            linear-gradient(to right, #2ea76d, #2ea76d);
                    }
                }

                & input[type='range']::-webkit-slider-runnable-track {
                    cursor: pointer;
                    -webkit-appearance: none;
                    appearance: none;
                    height: 12px;
                    box-shadow: none;
                    border: none;
                    background: transparent;
                }

                & input[type='range']:disabled {
                    user-select: none;
                    opacity: 0.3;
                    cursor: default;
                }
            }
        }
    }
    &__controls {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;

        &__audio {
            position: absolute;
            left: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            width: 200px;
            &__icon {
                svg {
                    fill: $clear-blue-grey;
                    opacity: 0.7;
                    transition: all 0.15s ease-in-out;
                }
            }
            &__range {
                display: flex;
                align-items: center;
                max-width: 0;
                height: 20px;
                transition: max-width 0.3s ease-in-out;

                & input[type='range'] {
                    z-index: 2;
                    -webkit-appearance: none;
                    appearance: none;
                    cursor: pointer;
                    width: 100%;
                    height: 6px;
                    background: transparent;
                    border-radius: 5px;
                    background-image: linear-gradient(to right, $blue-grey, $blue-grey);
                    background-repeat: no-repeat;
                }

                & input[type='range']::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    visibility: hidden;
                    transition: visibility 0s ease-in-out 0.25s;
                    cursor: grab;
                    appearance: none;
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;
                    background: $clear-blue-grey;
                }
            }

            &:hover {
                .media-controls__controls__audio__range {
                    max-width: 200px;
                    & input[type='range']::-webkit-slider-thumb {
                        transition: visibility 0s ease-in-out 0.1s;
                        visibility: visible;
                    }
                }
            }
        }
        &__buttons {
            display: flex;
            gap: 20px;
            align-items: center;

            button {
                $icon-button: 'go-to-max', 'go-to-frame', 'play', 'pause';
                background-color: transparent;
                border: none;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.2s ease-in-out;

                &:disabled {
                    opacity: 0.4;
                }

                @each $icon in $icon-button {
                    &[data-icon='#{$icon}']::before {
                        transition: all 0.2s ease-in-out;
                        margin-left: 2px;
                        content: '';
                        filter: invert(49%) sepia(10%) saturate(1118%) hue-rotate(184deg) brightness(93%) contrast(85%);
                        display: block;
                        background: url('../../../../../static/icons/studio/icon_#{$icon}.svg') no-repeat center;
                        background-size: contain;
                        background-color: transparent;
                        width: 15px;
                        height: 15px;
                    }
                }
                &[data-icon='pause']::before {
                    margin-left: 0px;
                }
                &[data-icon='play'],
                &[data-icon='pause'] {
                    border-radius: 50%;
                    background-color: $section-border-color;
                    width: 40px;
                    height: 40px;

                    &:hover {
                        background-color: #d1d7e480;
                    }
                }

                &[data-icon='go-to-frame']::before {
                    transform: translateX(-2px);
                }

                &.rotate {
                    transform: rotate(180deg);
                }

                &:hover {
                    background-color: $section-border-color;
                }
            }
        }
        &__data {
            position: absolute;
            right: 20px;
            display: flex;
            flex-direction: column;
            text-align: end;

            span {
                color: $clear-blue-grey;
            }
        }
    }
}

.track-editor {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    z-index: 2;
    pointer-events: none;

    &__range-container {
        width: 100%;
        height: 6px;
        position: relative;
        #range {
            background-color: #47dc95;
            position: absolute;
            height: 6px;

            &:hover {
                height: 10px;
            }
        }
    }

    & input[type='range'] {
        position: absolute;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        width: calc(100% - 40px);
        left: 20px;
        background: transparent;
        border-radius: 5px;
        background: none;
        background-repeat: no-repeat;

        &::-webkit-slider-thumb {
            border: none; /* get rid of Firefox thumb border */
            pointer-events: auto; /* catch clicks */
            -webkit-appearance: none;
            background: $clear-blue-grey;
            cursor: grab;
            appearance: none;
            height: 18px;
            width: 18px;
            border-radius: 50%;
        }
        &:disabled {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    #min {
        &::-webkit-slider-thumb {
            background: url('../../../../../static/icons/studio/icon_flag-start.svg') no-repeat center;
            background-size: 10px;
            background-color: #12804c;
        }
    }
    #max {
        &::-webkit-slider-thumb {
            background: url('../../../../../static/icons/studio/icon_flag-end.svg') no-repeat center;
            background-size: 10px;
            background-color: #12804c;
        }
    }
}
