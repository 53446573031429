.canvas-media,
.canvas-detections,
.canvas-blur-preview {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.canvas-media {
    z-index: 0;
}
.canvas-detections {
    z-index: 1;
}
.canvas-blur-preview {
    z-index: 2;
}
.loading {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
