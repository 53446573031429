@import "./variables";
@import "./functions";

// Mixin pour gérer les bordures arrondies communes
@mixin common-border-radius {
    border-radius: 13px;
}

.home {
    &__dropzone {
        border-radius: rem(12);
        min-height: 200px;
        width: 100%;
        position: relative;
        background: url("../../static/images/background-task.webp") center center no-repeat;
        background-size: cover;
        padding: rem(30);
        cursor: pointer;

        &:hover {
            .home__dropzone__image-container__img {
                background-size: 75%;
            }
        }

        &--disabled {
            cursor: not-allowed;
            pointer-events: none;
        }

        &--dragging {
            z-index: 10;
            > div {
                pointer-events: none;
            }
            .home__dropzone__image-container__img {
                background-size: 75%;
                pointer-events: none;
            }
            .home__dropzone__drag-preview {
                pointer-events: none;
                opacity: 1;
                z-index: 1;
            }
        }

        &__drag-preview {
            opacity: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 30px 40px;
            background: rgba($main-texts, 0.8);
            pointer-events: none;
            border-radius: 13px;
            transition: opacity 0.3s ease;
            &__drag-content {
                @include common-border-radius;
                border: 4px solid $white;
                border-style: dashed;
            }
        }

        &__image-container {
            &__img {
                @include common-border-radius;
                border: 3px solid $white;
                border-style: dashed;
                aspect-ratio: 1 / 1;
                height: 100%;
                background-image: url("../icons/tasks/icon-drop.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 70%;
                transition: background-size 0.5s ease;
                min-width: 150px;
            }
        }

        p {
            color: $secondary;
            margin-bottom: 0;
            font-size: rem(16);
            line-height: rem(20);
        }

        h2 {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            color: $secondary;
            margin-bottom: .5rem;
            font-size: rem(22);
            &::before {
                content: "";
                margin-right: rem(10);
                display: block;
                background: url("../../static/icons/tasks/icon-anonymization.svg") center center
                    no-repeat;
                background-size: contain;
                height: 30px;
                width: 30px;
                flex-shrink: 0;
            }
            span {
                margin-left: rem(6);
                margin-right: rem(6);
                font-size: rem(22);
                font-family: $font-semi-bold;
                color: $white;
            }
        }
        span {
            font-size: rem(14);
        }
    }

    &__trial-info-card {
        &__laius {
            color: $clear-blue-grey;
            font-size: 0.9rem;
            strong {
                font-family: $font-semi-bold;
            }
        }

        .trial-progress-bar {
            width: 100%;
            height: 22px;
            background-color: #e0e0e0;
            border-radius: 15px;
            overflow: hidden;
            margin-bottom: 5px;

            &__fill {
                height: 100%;
                border-radius: 15px;
                background-color: #4caf50; // Green color, you can change this
                transition: width 0.3s ease-in-out;
            }
        }

        .trial-progress-info {
            display: flex;
            justify-content: center;

            span {
                font-size: 0.8rem;
                color: $clear-blue-grey;
                font-style: italic;
            }
        }
    }


    &__pannel {
        
        &__message, &__title {
            color: $secondary;
        }            
        &__title {
            font-size: 1.375rem;
            font-family: $font-semi-bold;
        }
    }
}