@import './variables.scss';
.toolbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-top: 4px;
    padding-bottom: 4px;

    &__add-detections-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    button {
        height: 46px;
        width: 46px;
        border: none;
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        position: relative;
        &:disabled {
            opacity: 0.4;
            pointer-events: none;
        }

        .button__tooltip {
            position: absolute;
            top: 20%;
            left: 100%;
            padding: 5px;
            background-color: black;
            border-radius: 10px;
            min-width: 250px;
            z-index: 1000;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            $icons-tooltip: 'head', 'plate', 'other';
            @each $icon in $icons-tooltip {
                &[data-icon='#{$icon}']::before {
                    transition: all 0.2s ease-in-out;
                    margin-left: 2px;
                    content: '';
                    display: block;
                    background: url('../../../../../static/icons/studio/icon-tooltip-#{$icon}.svg') no-repeat center;
                    background-size: contain;
                    width: 50px;
                    height: 50px;
                }
            }

            span {
                text-align: left;
                color: white;
            }
        }

        $icons: 'plate', 'head', 'other', 'blur';
        @each $icon in $icons {
            &[data-icon='#{$icon}']::before {
                transition: all 0.2s ease-in-out;
                margin-left: 2px;
                content: '';
                filter: invert(49%) sepia(10%) saturate(1118%) hue-rotate(184deg) brightness(93%) contrast(85%);
                display: block;
                background: url('../../../../../static/icons/studio/icon-#{$icon}.svg') no-repeat center;
                background-size: contain;
                width: 30px;
                height: 30px;
            }
        }

        &:hover {
            background-color: #d1d7e480;
        }

        &.active {
            background-color: #d1d7e480;
            &::before {
                filter: invert(79%) sepia(13%) saturate(1814%) hue-rotate(96deg) brightness(92%) contrast(94%);
            }
        }

        .deactive-blur {
            transition: width 0.2s ease-in-out;
            width: 0px;
            height: 3px;
            background-color: $primary;
            transform: rotate(45deg) translateX(-14px) translateY(-6px);
            &.active {
                width: 46px;
            }
        }
    }
}
