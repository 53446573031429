@import '../../static/scss/variables';
@import '../../static/scss/functions';

textarea {
    @media screen and (max-width: $screen-lg) {
        font-family: $font-light;
    }
    border: 1px solid $clear-grey;
    &::-webkit-input-placeholder {
        font-size: small;
        opacity: 0.5 !important;
        color: $clear-blue-grey !important;
    }
    &:disabled {
        opacity: .75;
    }
}