@import "./variables";
@import "./functions";

.header {
    position: fixed;
    display: flex;
    padding: 0 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    color: $main-texts;
    background-color: $white;
    z-index: 100;

    @media screen and (min-width: $screen-lg) {
        width: calc(100% - #{$sidebar-width});
        left: $sidebar-width;
        padding: 0 3%;
        height: $header-height;

        &.full-width {
            width: 100%;
            left: 0;
            div .wis-logo-container > img {
                padding-left: 35px !important;
                width: 172px;
                height: 51px;
                margin-bottom: 15px;
            }
        }
    }

    &:not(.header-task) {
        .btn-header-task {
            display: none;
        }
    }

    &.header-task {
        .btn-header-task {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
        }
    }

    .page-subsection {
        border-radius: 30px;
        padding: 5px 10px;
        color: $clear-blue-grey;
        background-color: $light-blue-grey;
        font-family: $font-semi-bold;
    }

    .trial-section {
        .title {
            color: $clear-blue-grey !important;
            font-family: $font-semi-bold;
        }
        .data {
            color: $clear-blue-grey !important;
            font-family: $font-light;
        }
    }
}

.main-title {
    font-size: rem(20);

    @media screen and (min-width: $screen-lg) {
        font-size: rem(30);
    }
}

.menu-dropdown-container {
    position: relative;

    .menu-dropdown {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        padding: 0 12px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.05);
        max-width: 300px;

        section {
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: "...";
            white-space: nowrap;
            padding-top: 10px;
            padding-bottom: 10px;

            span,
            li {
                color: $clear-blue-grey;
                font-family: $font-primary;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $clear-grey;
            }

            &.user-area {
                cursor: pointer;
                display: inline;

                .name {
                    font-size: rem(20);
                    font-family: $font-semi-bold;
                }
                .email {
                    font-size: rem(12);
                }
                &:hover {
                    > div > .account-badge {
                        background-color: #33e394;
                    }
                }
            }

            li:hover {
                text-decoration: underline;
            }
        }
    }
}

.account-badge {
    height: 45px;
    width: 45px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: $green;
    color: $white;
    border: 1px solid $light-grey;
    font-family: $font-semi-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(12);
    &:hover {
        background-color: #33e394;
    }
}
