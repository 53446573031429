@import "./variables.scss";

.sidebar-container {
    left: 0;
    top: 0;
    position: fixed;
    z-index: 100;
    width: $sidebar-width;
    height: 100vh;
    padding: 0.5rem 0.5rem 0 2rem;
    z-index: 101;

    .wis-logo-container {
        width: 100%;
        padding: 15px 0;
    }

    .nav {
        height: calc(100% - 105px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        ul {
            width: 100%;

            li {
                margin-top: 1px;
                height: 60px;
                position: relative;
                margin-bottom: 10px;

                &.sub-menu {
                    height: 40px;
                }

                a,
                button {
                    display: flex !important;
                    align-items: center;
                    height: 100%;
                    padding: 0 0 0 20px;
                    font-size: 0.875rem;
                    line-height: 3.5rem;
                    width: 100%;
                    transition: all ease-in-out 0.25s;

                    &.sub-menu {
                        padding-left: 0;
                        > span {
                            font-family: $font-light !important;
                            font-size: 0.7rem !important;
                        }
                    }

                    span {
                        font-family: $font-semi-bold !important;
                        margin-left: 35px;
                    }

                    &:not(.sub-menu) {
                        &:hover {
                            border-radius: 10px;
                            background-color: $light-blue-grey;
                        }
                        &.active {
                            background-color: $light-blue-grey;
                            border-radius: 10px;
                            & > span {
                                color: $primary;
                            }
                        }
                    }
                    &.submenu,
                    &.active {
                        > span {
                            color: $black;
                            font-family: $font-semi-bold !important;
                        }
                    }
                }
            }
        }
    }
}

a[class^="icon-"] {
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        background-size: contain;
        width: 20px;
        height: 20px;
        display: block;
    }

    &[class*="account"] {
        &::before {
            background: transparent url("../icons/sidebar/account.svg") no-repeat center;
            background-size: contain;
        }
        &.active:before {
            background: transparent url("../icons/sidebar/account-active.svg") no-repeat center;
            background-size: contain;
        }
    }

    &[class*="dashboard"] {
        &::before {
            background: transparent url("../icons/sidebar/dashboard.svg") no-repeat center;
            background-size: contain;
        }
        &.active:before {
            background: transparent url("../icons/sidebar/dashboard-active.svg") no-repeat center;
            background-size: contain;
        }
    }

    &[class*="taskmanager"] {
        &::before {
            background: transparent url("../icons/sidebar/taskmanager.svg") no-repeat center;
            background-size: contain;
        }
        &.active:before {
            background: transparent url("../icons/sidebar/taskmanager-active.svg") no-repeat center;
            background-size: contain;
        }
    }

    &[class*="home"] {
        &::before {
            background: transparent url("../icons/sidebar/home.svg") no-repeat center;
            background-size: contain;
        }
        &.active:before {
            background: transparent url("../icons/sidebar/home-active.svg") no-repeat center;
            background-size: contain;
        }
    }
    &[class*="ressources"] {
        &::before {
            background: transparent url("../icons/sidebar/home.svg") no-repeat center;
            background-size: contain;
        }
        &.active:before {
            background: transparent url("../icons/sidebar/home-active.svg") no-repeat center;
            background-size: contain;
        }
    }

    &[class*="help"] {
        &::before {
            background: transparent url("../icons/sidebar/help.svg") no-repeat center;
            background-size: contain;
        }
        &.active:before {
            background: transparent url("../icons/sidebar/help-active.svg") no-repeat center;
            background-size: contain;
        }
    }
}

.helper {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 20px;
    background: $helper-background;

    @media screen and (max-height: 642px) {
        display: none;
    }

    &__title {
        font-size: 15px;
        line-height: 22px;
        font-weight: $font-semi-bold;
        color: $clear-blue-grey;
        margin-bottom: 8px;
    }

    &__text {
        font-size: 13px;
        line-height: 20px;
        font-family: $font-light;
        color: $clear-blue-grey;
        margin-bottom: 0;
    }

    a {
        color: $primary;
        font-family: $font-medium;
        text-decoration: underline;
        transition: color 0.25s ease;

        &:hover {
            color: $hover-main !important;
        }
    }
}
