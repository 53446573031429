@import "../../static/scss/variables";
@import "../../static/scss/functions";

$left-card-width: 30%;
$card-width: 22%;

.plans-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;

    .plans-container__title {
        font-family: $font-semi-bold;
    }

    .plans-container__card {
        padding: 26px 20px;
        width: 32%;
        max-width: 340px;
        min-width: 320px;
        display: flex;
        flex-direction: column;
        border: 2px solid #e1e5ed;
        border-radius: 10px;
        background-color: #f6f8fc;
        margin-bottom: 30px;
        height: fit-content;
        &.active,
        &:hover {
            border-color: $primary;
        }
        .plans-container__card__title {
            font-family: $font-semi-bold;
            font-size: rem(20);
            color: $secondary;
            margin-bottom: 20px;
        }

        .plans-container__card__price {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            min-height: 50px;
            margin-bottom: 16px;

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            label {
                text-transform: uppercase;
            }
            strong {
                color: $primary;
                font-size: rem(30);
                font-family: $font-semi-bold;
            }
        }

        .plans-container__card__specifications {
            ul {
                margin-bottom: 30px;
                list-style: none;
                li {
                    svg {
                        flex-shrink: 0;
                        width: 20px;
                        height: 20px;
                        margin-top: 0.125rem;
                    }
                    display: flex;
                    align-items: flex-start;
                    gap: 0.625rem;
                    span {
                        font-size: 0.8rem;
                        text-align: left;
                    }
                }
            }
            .plans-container__card__specifications__pro {
                display: flex;
                align-items: center;
                h2 {
                    font-family: $font-semi-bold;
                    font-size: rem(25);
                }
                sup {
                    font-family: $font-light;
                    vertical-align: super;
                    margin-left: 4px;
                    text-transform: uppercase;
                    line-height: 0.5rem;
                    font-size: rem(16);
                }
            }
        }
        .plans-container__card__button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.plan-trial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    h3 {
        color: $clear-blue-grey;
        font-family: $font-primary;
        margin-bottom: 30px;
        strong {
            font-family: $font-semi-bold;
        }
    }

    // Form-trial
    .form-trial {
        width: 80%;
        .form-group {
            @media screen and (min-width: $screen-lg) {
                width: calc(50% - 12px);
            }
        }
    }
}

i {
    color: $clear-blue-grey;
    font-size: rem(12);
}

// Form-trial
.form-trial {
    .form-group {
        width: 80%;

        @media screen and (min-width: $screen-lg) {
            width: calc(50% - 12px);
        }
    }
}

.modal-confirmation-footer {
    position: absolute;
    color: $main-texts;
    transform: translateX(-50%);
    bottom: 12px;
}
