@import "./variables";
@import "./functions";

.ressources {
    position: relative;
    width: 100%;
    height: 100%;
    padding: rem(40);
    border-radius: rem(10);
    overflow: hidden;
    color: white;
    display: flex;
    gap: 3rem;
    flex-direction: column;
    flex-wrap: wrap;
    @media screen and (min-width: $screen-lg) {
        flex-direction: row;
        justify-content: space-between;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../images/plugin-bg.jpg") no-repeat center center;
        background-size: cover;
        z-index: -1;
    }

    .intro {
        margin-bottom: rem(30);

        &__title {
            display: flex;
            align-items: center;
            gap: rem(10);
            margin-bottom: rem(10);

            svg {
                width: 36px;
                height: 36px;
            }

            h2 {
                margin-bottom: 0;
                font-size: rem(32);
                font-family: $font-semi-bold;

                strong {
                    color: $primary;
                }
            }
        }

        &__loading {
            font-size: rem(12);
            font-style: italic;
            font-family: $font-light;
        }

        p {
            font-size: rem(15);
            color: white;
            font-family: $font-light;
        }
    }

    .plugins {
        display: flex;
        flex-direction: column;
        gap: rem(20);

        .plugin {
            h3 {
                font-size: rem(18);
                font-family: $font-medium;
                margin-bottom: rem(16);
            }

            .actions {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: normal;
                flex-wrap: wrap;
                gap: rem(16);

                .download {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: rem(8);

                    &:empty {
                        display: none;
                    }

                    &__disabled {
                        pointer-events: none !important;
                        opacity: 0.5 !important;
                        cursor: not-allowed !important;
                    }

                    &__main {
                        display: flex;
                        gap: rem(4);
                        align-items: center;
                        justify-content: center;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    &__other {
                        font-size: rem(11);
                        color: white;
                        font-family: $font-light;
                        font-style: italic;
                        text-decoration: underline;

                        &:hover {
                            color: $primary !important;
                        }
                    }
                }
            }
        }
    }

    .progressbar {
        background: white;
        border: 4px solid #3333334d;
        border-radius: 12px;
        padding: rem(14) rem(10) rem(18);
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-start;
        h4 {
            font-size: rem(34);
            color: $secondary;
            margin-bottom: 0;
            font-family: $font-semi-bold;

            &.subtitle {
                font-size: rem(16);
                font-style: italic;
                font-family: $font-primary;
                margin-bottom: 1rem;
                color: rgba($secondary, 0.7);
            }
        }
        h5 {
            font-size: rem(14);
            font-family: $font-semi-bold;
            color: $secondary;
            margin-bottom: 0.2rem;
        }
        p {
            font-size: rem(16);
            color: rgba($secondary, 0.7);
            margin-bottom: 0;
            line-height: 0.9;
            small {
                font-style: italic;
            }
        }
        .period {
            display: flex;
            max-width: 230px;
            gap: 1rem;
        }

        .progress-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 36px;

            .progress {
                display: flex;
                flex-direction: column-reverse;
                width: 12px;
                height: 120px;
                background: #d9d9d9;

                &.beginning:after {
                    content: "";
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: $primary;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                .last-past {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
            &:before {
                content: "";
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: $primary url("../../static/icons/icon_gift.svg") no-repeat center;
                background-size: 22px;
                top: 4px;
                position: relative;
            }
            &:after {
                content: "";
                border: 2px solid #d9d9d9;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: white url("../../static/icons/icon_rocket.svg") no-repeat center;
                background-size: 22px;
                top: -4px;
                position: relative;
            }
        }

        .legend {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 230px;
            padding: rem(10) 0 0;
        }
    }
}
