@import "../../static/scss/variables";
@import "../../static/scss/functions";

// Modal
.modal {
    background: $white;
    width: 100% !important;
    padding: 40px 20px !important;
    max-width: 650px !important;
    border-radius: rem(10px) !important;
    width: 100% !important;
    height: fit-content !important;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    font-size: rem(14) !important;

    &:focus {
        outline: 0;
    }

    h2 {
        color: $clear-blue-grey;
    }

    @media screen and (max-width: $screen-lg) {
        h2 {
            font-size: rem(20) !important;
            line-height: rem(30) !important;
            margin: 0 0 10px !important;
        }
    }

    .label-text {
        color: $clear-blue-grey;
        font-style: italic;
    }
}

.modal-message {
    font-family: $font-light;
    font-size: rem(12);
    line-height: rem(18);
    text-align: center;
    margin: 0 0 15px;
    color: $clear-blue-grey;
    white-space: pre-line !important;

    strong {
        font-family: $font-semi-bold;
    }

    @media screen and (min-width: $screen-lg) {
        font-family: $font-primary;
        margin: 0 0 15px;
        font-size: rem(13);
        line-height: rem(22);
    }
}
