@import './variables.scss';
@import './functions';

//FONT COLOR
$colors-map: (
    'white': $white,
    'black': $black,
    'purple': $purple,
    'primary': $primary,
    'secondary': $secondary,
    'error': $error,
    'green': $success,
    'light-grey': $light-grey,
    'semi-light-grey': $semi-light-grey,
    'clear-blue-grey': $clear-blue-grey,
    'clear-text': $clear-text,
    'clear-grey': $clear-grey,
);

//COLORS
@mixin colors {
    @each $color, $attr in $colors-map {
        &-#{$color} {
            color: $attr !important;
        }
    }
}

.color {
    @include colors;
}

//BG COLORS
@mixin bgs {
    @each $color, $attr in $colors-map {
        &-#{$color} {
            background-color: $attr !important;
        }
    }
}

.bg {
    @include bgs;
}

//FONT WEIGHT
$weight-map: (
    'bold': bold,
);

@mixin texts {
    @each $weight, $attr in $weight-map {
        &-#{$weight} {
            font-weight: $attr;
        }
    }
}

.text {
    @include texts;
}

//MARGIN RIGHT
$margins-right-map: (
    1: 1rem,
    2: 1.5rem,
    3: 2rem,
    4: 3rem,
);

@mixin margins-right {
    @each $mr, $attr in $margins-right-map {
        &-#{$mr} {
            margin-right: $attr;
        }
    }
}

.mr {
    @include margins-right;
}

//MARGIN LEFT
$margins-left-map: (
    0: 0,
    1: 1rem,
    2: 1.5rem,
    3: 2rem,
    4: 3rem,
);

@mixin margins-left {
    @each $ml, $attr in $margins-left-map {
        &-#{$ml} {
            margin-left: $attr;
        }
    }
}

.ml {
    @include margins-left;
}

//BORDER
.border-bottom-none {
    border-bottom: none !important;
}

//PADDING

//PADDING LEFT
$paddings-left-map: (
    0: 0,
    1: 1rem,
    2: 1.5rem,
    3: 2rem,
    4: 3rem,
    5: 4rem,
);

@mixin paddings-left {
    @each $pl, $attr in $paddings-left-map {
        &-#{$pl} {
            padding-left: $attr !important;
        }
    }
}

.pl {
    @include paddings-left;
}

//PADDING RIGHT
$paddings-right-map: (
    0: 0,
    1: 1rem,
    2: 1.5rem,
    3: 2rem,
    4: 3rem,
    5: 4rem,
);

@mixin paddings-right {
    @each $pr, $attr in $paddings-right-map {
        &-#{$pr} {
            padding-right: $attr !important;
        }
    }
}

.pr {
    @include paddings-right;
}

.w-100-mobile {
    width: 100% !important;

    .select-container {
        width: 50%;
        margin-left: auto;
    }

    @media screen and (min-width: $screen-lg) {
        width: auto !important;

        &.w-50 {
            width: 50% !important;
        }

        .select-container {
            width: auto;
        }
    }
}

.w-50-mobile {
    width: 50% !important;

    .select-container {
        width: 100%;
    }

    @media screen and (min-width: $screen-lg) {
        width: auto !important;

        &.w-50 {
            width: 50% !important;
        }

        .select-container {
            width: auto;
        }
    }
}

// Data open
[data-open='true'] {
    display: flex !important;
    margin-top: 15px;

    @media screen and (min-width: $screen-lg) {
        margin-top: 0;
    }
}

[data-open='false'] {
    display: none !important;

    // Prevent toggle issue on desktop
    @media screen and (min-width: $screen-lg) {
        display: flex !important;
    }
}

// Border
.border {
    @media screen and (max-width: $screen-lg) {
        &.border-mobile {
            border: 1px solid $grey-white !important;
        }
    }
}

// Shadow Mobile
.shadow-mobile {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

    @media screen and (min-width: $screen-lg) {
        box-shadow: none;
    }
}

// Radius Mobile
.border-radius-mobile {
    border-radius: 15px;

    @media screen and (min-width: $screen-lg) {
        border-radius: 0;
    }
}

// Form order
@for $i from 0 through 10 {
    .form-order-#{$i} {
        order: $i;

        @media screen and (min-width: $screen-lg) {
            order: inherit;
        }
    }
}
