@import './variables.scss';

.studio-layout {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;

    &__header {
        width: 100%;
        height: $header-height;
        display: flex;

        &__logo {
            border-right: 2px solid $section-border-color;
            background-color: white;
            width: $sidebar-width;
        }
        &__actions {
            border-bottom: 2px solid $section-border-color;
            flex-grow: 1;
            background-color: white;
        }
    }

    &__container {
        flex-grow: 1;
        display: flex;
        width: 100%;
        &__sidebar {
            border-right: 2px solid $section-border-color;
            width: $sidebar-width;
            background-color: white;
        }

        &__editor {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            &__media-visualizer {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-bottom: 2px solid $section-border-color;
                background-color: #f8f9fb;

                &__tool-box {
                    width: 55px;
                    height: 100%;
                    background-color: $section-border-color;
                    border-right: 2px solid $section-border-color;
                }

                &__content {
                    width: 100%;
                    height: calc(100vh - $header-height - $controls-height);
                    position: relative;
                }
            }

            &__media-controls {
                height: $controls-height;
                background-color: white;
            }
        }
    }
}
