@import "./variables";
@import "./functions";

.page-container {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    min-height: calc(100% - 70px);
    padding-bottom: var(--bs-gutter-x, 0.75rem);
    padding: 0 15px;

    &.task-container {
        min-height: calc(100% + 70px);
    }

    @media screen and (min-width: $screen-lg) {
        top: $header-height;
        left: $sidebar-width;
        width: calc(100% - #{$sidebar-width});
        min-height: calc(100% - #{$header-height});

        &.full-width {
            width: 100%;
            left: 0;
        }
    }

    .middle-container {
        padding: 0;

        @media screen and (min-width: $screen-lg) {
            padding: 2%;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: $box-shadow;
        }

        &.shadow-none {
            box-shadow: none;
        }

        @media screen and (min-width: $screen-lg) {
            &.bordered {
                //border: 2px solid $light-blue-grey;
            }
        }

        &-title {
            font-size: rem(20);
            margin-bottom: 0;

            @media screen and (min-width: $screen-lg) {
                font-size: rem(22);
            }
        }

        @media screen and (max-width: $screen-lg) {
            &.middle-container-developer {
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
                border-radius: 30px;
                padding: 25px 20px 30px;
            }
        }

        &.dash-mobile-container {
            background: $grey-white;
            padding: 20px;
            border-radius: rem(10);

            h2 {
                font-size: rem(16);
            }

            @media screen and (min-width: $screen-lg) {
                background: inherit;

                h2 {
                    font-size: rem(22);
                }
            }
        }
    }
}

.page-payment {
    background-color: $grey-white;

    .plan-container {
        padding: 4rem;
    }
}
