@import "../../static/scss/variables";

$shimmer-bg: $light-grey;
$shimmer-gradient: linear-gradient(
    to right,
    $light-grey 0%,
    $clearer-grey 20%,
    $light-grey 40%,
    $light-grey 100%
);

.skeleton {
    display: flex;
    align-items: flex-end;
    gap: 0.25rem;
}

.skeleton-label {
    display: block;
    background: $light-grey;
    background: linear-gradient(110deg, $clear-text 8%, $light-grey 18%, $clear-text 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    height: 0.8125rem;
    margin: 0;
    color: transparent;
}

.skeleton-placeholder {
    display: block;
    background: $light-grey;
    background: linear-gradient(110deg, $light-grey 8%, $clearer-grey 18%, $light-grey 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    min-height: 0.8125rem;
    width: 100px;
    margin: 0;

    &.style-block {
        min-width: 140px !important;
        height: 100%;
        opacity: 0.4;
    }
    &.style-border {
        width: 100%;
        height: 100%;
        opacity: 0.4;
    }
}

.skeleton--graph--container {
    .skeleton--graph {
        background: white;
        padding: 0 4% 0.15rem;
        margin: 0 4%;

        .skeleton--graph__shimmer {
            animation: placeHolderShimmer 1.5s linear infinite forwards;
            background: $shimmer-bg;
            background-image: $shimmer-gradient;
            background-repeat: no-repeat;
            background-size: 800px 220px;
            height: 220px;
            position: relative;
            display: flex;

            div {
                background: white;
            }

            .sep {
                height: 100%;
                width: 1px;
                right: auto;
            }

            .top {
                width: 4%;
                top: 0;
            }

            .space {
                width: 100%;
                height: 12px;
            }

            .row {
                height: 12px;
            }

            @for $i from 1 through 40 {
                .sep--#{$i} {
                    left: #{$i * 20}px;
                    top: 0;
                }
            }

            $heights: 10px, 20px, 50px, 30px, 10px, 70px, 40px, 25px, 60px, 15px, 35px, 45px, 55px,
                65px, 75px, 85px, 0px, 70px, 40px, 25px, 60px, 10px, 20px, 50px, 30px, 10px, 70px,
                40px, 25px, 60px, 15px, 35px, 45px, 55px, 65px, 75px, 85px, 0px, 70px, 40px, 25px,
                60px;

            @for $i from 1 through 40 {
                .top--#{$i} {
                    left: #{($i - 1) * 20}px;
                    height: #{nth($heights, $i)};
                }
            }
        }
    }

    .skeleton--graph__label {
        display: flex;
        justify-content: center;
        padding: 1rem 4% 0;
        margin: 0 10%;
        gap: 1rem;
        div {
            display: flex;
            gap: 0.25rem;
            label {
                @extend .skeleton-placeholder;
            }
            &:before {
                content: "";
                display: block;
                border-radius: 4px;
                background-color: $light-grey;
                width: 14px;
                height: 14px;
            }
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
