@import "./variables";
@import "./functions";

$breakpoint-mobile: 991px;

#image-login {
    overflow: hidden;
    position: relative;
    padding: 0;

    .login-background-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2); // 20% black overlay
        pointer-events: none; // Allows clicks to pass through to the image
    }
}

.auth-container {
    width: 360px;
    min-height: 485px;

    @media (max-width: $breakpoint-mobile) {
        width: 250px;
    }

    svg {
        max-width: 200px;
    }

    #login-laius {
        font-family: $font-primary;
        color: $clear-blue-grey;
        margin: 0 0 30px;
    }

    form {
        max-height: auto;

        @media screen and (min-width: $screen-lg) {
            max-height: 260px;
        }

        #forgotten-password {
            color: $clear-blue-grey;
            text-decoration: underline;
        }
    }

    .login-success {
        font-size: 30px;
    }

    &.plugin-container {
        width: 480px;
    }
}

.create-account {
    color: $blue-grey;
    text-decoration: underline;

    &:hover {
        color: $clear-blue-grey !important;
    }
}

// Error message
.credential-error {
    border-radius: 25px;
    padding: 7px 10px 7px 55px;
    font-size: rem(13);
    background:
        url("../icons/info-error.svg") no-repeat 17px 50%,
        rgba(208, 2, 27, 0.05);
}

#validation-container {
    .invalid-text {
        color: $processing;
    }
    p {
        font-size: rem(12);
    }
}
