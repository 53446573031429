@import "../../static/scss/variables";
@import "../../static/scss/functions";

input[type="text"],
input[type="email"],
input[type="tel"] {
    font-size: rem(13);
}

input:read-only:not(:disabled) {
    display: block;
    padding: 0 !important;
    margin-bottom: 0;
    line-height: 1.5;
    color: $clear-blue-grey;
    background-color: transparent;
    border: none !important;
    border-width: 1px 0;
    height: auto !important;

    &:focus,
    &::selection {
        border: none !important;
        border-radius: 0;
        color: $clear-blue-grey;
    }

    &::selection {
        background-color: $clear-grey;
    }
}

input::-webkit-input-placeholder {
    font-size: small;
    opacity: 0.5 !important;
    color: $clear-blue-grey !important;
}
input:focus::-webkit-input-placeholder {
    opacity: 1 !important;
}

label {
    @media screen and (max-width: $screen-lg) {
        font-family: $font-semi-bold;
    }

    &.is-invalid {
        color: red !important;
    }
    sup {
        color: $primary;
        &.is-invalid {
            color: red !important;
        }
    }
}

.form-group {
    margin-bottom: 1rem;
    .error-container {
        position: relative;
    }
    i {
        position: absolute;
        color: red;
        font-size: rem(13);
    }
    label {
        margin-bottom: 0.5rem;
    }
}

.input-text {
    border: 1px solid $clear-grey !important;
    height: 40px;
    padding: 11px 13px 11px 44px !important;

    @media screen and (max-width: $screen-lg) {
        font-family: $font-light;
    }

    &.is-invalid {
        color: red;
        &:focus {
            border: 1px solid red !important;
        }
        border: 1px solid red !important;
    }

    &:focus:not(:read-only),
    &.input-filled:focus:not(:read-only) {
        border: 1px solid $clear-blue-grey !important;
    }

    &.input-filled:not(:read-only) {
        border: 1px solid $clear-blue-grey !important;

        @media screen and (max-width: $screen-lg) {
            border: 1px solid $grey-white !important;
        }
    }

    &.rounded {
        border-radius: 20px !important;
    }
}

.form-control.is-invalid {
    background-image: none !important;
}

//input icons (login, pswd)
div[class^="icon-"] {
    position: relative;

    &::before {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        content: "";
        background-size: contain;
        width: 20px;
        height: 20px;
        display: block;
    }
    &[class*="login"] {
        &::before {
            background: transparent url("../../static/icons/icon-user.svg") no-repeat center;
        }
        &.active::before {
            background: transparent url("../../static/icons/icon-user-active.svg") no-repeat center;
        }
        &.is-invalid::before {
            background: transparent url("../../static/icons/icon-user-invalid.svg") no-repeat center;
        }
    }
    &[class*="email"] {
        &::before {
            background: transparent url("../../static/icons/icon-mail.svg") no-repeat center;
        }
        &.active::before {
            background: transparent url("../../static/icons/icon-mail-active.svg") no-repeat center;
        }
        &.is-invalid::before {
            background: transparent url("../../static/icons/icon-mail-invalid.svg") no-repeat center;
        }
    }
    &[class*="password"] {
        &::before {
            background: transparent url("../../static/icons/icon-password.svg") no-repeat center;
        }
        &.active::before {
            background: transparent url("../../static/icons/icon-password-active.svg") no-repeat
                center;
        }
        &.is-invalid::before {
            background: transparent url("../../static/icons/icon-password-invalid.svg") no-repeat
                center;
        }
    }
}

// show password icon
.show-password {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.inline {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    min-height: 20px;

    label {
        margin-bottom: 0;
        font-weight: bold;
        font-family: "Poppins semi-bold", sans-serif;
        flex-shrink: 0;
    }

    > div {
        width: 100%;
    }
}

.style-block {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: initial;
    height: 40px;
    label {
        margin-bottom: 0;
        font-weight: bold;
        font-family: "Poppins semi-bold", sans-serif;
        flex-shrink: 0;
        min-width: 140px;
    }
    input:read-only:not(:disabled) {
        padding: 10px 15px;
        width: initial;
        text-align: center;
        height: 40px !important;
        border-radius: 3px;
        background-color: $grey-white;
        text-transform: uppercase;
        min-width: 140px;
    }
    > div {
        width: initial;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}

.style-border {
    .form-control:disabled {
        background-color: white;
        padding: 0.5rem !important;
    }
    > div {
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}
