@import './variables.scss';

$sidebar-header-height: 50px;

.sidebar {
    width: $sidebar-width;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 20px;
    position: relative;

    &__header {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        height: $sidebar-header-height;
        cursor: pointer;

        &__title {
            height: 100%;
            display: flex;
            align-items: center;
            width: 50%;

            &.active {
                border-bottom: 1px solid $primary;
                span {
                    color: $primary;
                }
            }

            &.inactive {
                opacity: 0.4;
                border-bottom: 1px solid $clear-blue-grey;
            }

            &__badge {
                border-radius: 30px;
                border: 1px solid $primary;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px 4px;
                margin-bottom: 10px;
                margin-left: 10px;
                small {
                    font-size: 8px;
                    color: $primary;
                }
            }
        }
    }

    &__thumbs-grid-container {
        position: absolute;
        top: $sidebar-header-height + 10px;
        left: 20px;
        max-height: calc(100% - 100px);
        min-height: 200px;
        width: 300px;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;

        &__no-data,
        &__loading {
            display: flex;
            margin-top: 50px;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            span {
                color: $clear-blue-grey;
            }
        }
        &__loader {
            position: absolute;
            z-index: 1000;
            transform: translateX($sidebar-width / 2 - 50px);
            margin-top: -80px;
            width: 50px;
            height: 50px;
        }
        &__thumb-wrapper {
            position: relative;
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: scale 0.2s ease-in-out;
            cursor: pointer;
            &:hover {
                scale: 1.1;
            }
            &.hover {
                scale: 1.1;
            }
            canvas {
                background-color: $clear-grey;
                width: 85%;
                height: 85%;
                border-radius: 5px;
            }

            .checkbox-activation {
                position: absolute;
                top: 10px;
                right: 10px;
                margin-bottom: 0;
            }
        }
    }

    &__loading-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        &__data {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

@keyframes left-to-right {
    0% {
        left: -120%;
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        left: 20px;
        opacity: 1;
    }
}

@keyframes right-to-left {
    0% {
        left: 20px;
        opacity: 1;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        left: -120%;
        opacity: 0;
    }
}

.modal-delete {
    position: absolute;
    z-index: 10;
    width: 90%;
    height: 120px;
    background-color: white;
    box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.1);
    top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    gap: 10px;

    &__title {
        font-family: $font-light;
        color: black;
        font-size: 12px;
    }

    &__buttons-container {
        display: flex;
        justify-content: space-around;
        gap: 20px;

        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px !important;
            height: 26px;
            padding: 5px;
            border-radius: 5px;
            font-family: $font-light;
            font-size: 12px;

            &.cancel {
                border: 1px solid $clear-grey;
                background-color: white;
                color: $primary;
                &:hover {
                    background: $primary;
                    color: white;
                }
            }
            &.confirm {
                border: none;
                background-color: $primary;
                color: $white;
                &:hover {
                    background-color: $hover-main;
                }
            }
        }
    }
}

.card-animated {
    position: absolute;
    top: $sidebar-header-height + 5px;
    left: 20px;
    width: calc(100% - 30px);
    box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.1);
    background-color: $white;
    border-radius: 15px;
    padding: 20px 15px;
    &.open {
        animation: 0.3s left-to-right;
    }

    &.close {
        animation: 0.3s right-to-left;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        &__title {
            display: flex;
            align-items: center;
            gap: 10px;
            h4 {
                font-family: $font-semi-bold;
                margin-bottom: 4px;
            }
            span {
                font-family: $font-light;
                font-size: 12px;
            }
        }

        .close-btn {
            height: 30px;
            width: 30px;
            background-color: transparent;
            border: none;
            border-radius: 50%;
            transition: all 0.2s ease-in-out;
            &::before {
                display: block;
                content: '';
                height: 100%;
                width: 100%;
                background: transparent url('../../../../../static/icons/studio/CloseButton.svg') no-repeat center;
                background-size: contain;
                filter: invert(49%) sepia(10%) saturate(1118%) hue-rotate(184deg) brightness(93%) contrast(85%);
            }
            &:hover {
                background-color: $section-border-color;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 25px;

        &__detection-section {
            display: flex;
            justify-content: space-between;

            &__thumb {
                width: 100px;
                height: 100px;
                > canvas {
                    background-color: $clear-grey;
                    border-radius: 15px;
                }

                &__no-detection {
                    background-color: $clear-grey;
                    border-radius: 15px;
                    width: 100%;
                    height: 100%;
                    &::before {
                        width: 100%;
                        height: 100%;
                        content: '';
                        display: block;
                        background: url('../../../../../static/icons/studio/icon_no-detection.svg') no-repeat center;
                        background-size: contain;
                    }
                }
            }

            &__actions {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 5px;

                span {
                    font-size: 12px;
                }
                &__activation {
                    display: flex;
                    align-items: end;
                    align-items: center;
                    gap: 20px;
                    cursor: pointer;
                    .checkbox {
                        margin-right: 28px;
                        margin-top: 10px;
                    }
                }
                &__delete-track {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    gap: 10px;
                    padding: 2px 0px;
                    cursor: pointer;
                    button {
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        background-color: white;
                        transition: background-color 0.2s ease-in-out;
                        &::after {
                            height: 26px;
                            width: 26px;
                            margin-top: 4px;
                            content: '';
                            display: block;
                            filter: invert(49%) sepia(10%) saturate(1118%) hue-rotate(184deg) brightness(93%)
                                contrast(85%);
                            background: url('../../../../../static/icons/studio/icon_delete.svg') no-repeat center;
                            background-size: cover;
                        }
                        &:hover {
                            background-color: #eef1f8;
                        }
                        &:active {
                            background-color: #d1d7e480;
                        }
                    }
                }
            }
        }

        &__data-section {
            border: 2px solid $section-border-color;
            background-color: #f8f9fb;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            &__writable {
                padding: 15px;
                display: grid;
                grid-template-columns: 3fr 3fr 2fr;
                gap: 20px;
                &__label {
                    height: 30px;
                    align-items: center;
                    display: flex;
                    font-size: 12px;
                    font-family: $font-light;
                    color: black;
                }
                &__input {
                    height: 30px;
                    justify-content: end;
                    align-items: center;
                    display: flex;
                    border: 1px solid $section-border-color;
                    border-radius: 5px;
                    width: 100%;
                    color: $clear-blue-grey;
                    font-size: 12px;
                }
                &__timestamp {
                    margin-left: -15px;
                    height: 30px;
                    justify-content: end;
                    align-items: center;
                    display: flex;
                    font-size: 12px;
                    font-family: $font-light;
                    color: black;
                }
            }

            &__readable {
                padding: 15px;
                display: grid;
                grid-template-columns: 2fr 1fr;
                gap: 25px;

                &__label {
                    align-items: center;
                    display: flex;
                    font-size: 12px;
                    font-family: $font-light;
                    color: black;
                }
                &__value {
                    justify-content: end;
                    align-items: center;
                    display: flex;
                    font-size: 12px;
                    font-family: $font-light;
                    color: $clear-blue-grey;

                    &.cn {
                        text-transform: capitalize;
                    }
                }
            }
        }

        &__buttons-section {
            display: flex;
            justify-content: space-around;

            button {
                font-size: 0.8125rem;
                width: 120px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 8px;

                &.cancel {
                    background-color: $white;
                    color: $primary;
                    border: 1px solid $section-border-color;
                    &:hover {
                        background-image: $hover-main;
                        color: $white;
                    }
                    &:active {
                        color: $white;
                        background-image: linear-gradient(to right top, #8a0039, #8a0039, #8a0039, #8a0039, #8a0039);
                    }
                }

                &:disabled {
                    opacity: 0.3;
                }
            }
        }
    }
}

.arrow-input {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    border: 1px solid $section-border-color;
    border-radius: 5px;
    padding: 5px;
    gap: 5px;
    background-color: white;
    span {
        color: $clear-blue-grey;
        font-size: 12px;
    }

    &__arrow-container {
        display: flex;
        flex-direction: column;
        gap: 4px;

        button {
            height: 10px !important;
            width: 20px !important;
            border-radius: 4px;
            background: none;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: none;

            &:hover {
                background-color: #f8f9fb;
            }
            .rotate {
                transform: rotate(180deg);
            }
        }
    }
}
