/*
    MIXINS
*/

// REM
$font-size: 16px;

@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}

@function rem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit($font-size)}rem;
}

// Media query
@mixin media-min-width($min) {
    @media screen and (min-width: $min) {
        @content;
    }
}

@mixin media-max-width($max) {
    @media screen and (max-width: $max) {
        @content;
    }
}
