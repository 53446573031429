@import "./variables";
@import './_functions';

.error {
    border-radius: 25px;
    padding: 11px 10px 11px 55px;
    font-size: rem(13);
    background: url("../icons/info-error.svg") no-repeat 17px 50%, rgba(208, 2, 27, 0.05);
}

.error-btn {
    height: 36px !important;
}

.error-card-expired-account {
    >span {
        color: $error;
    }
}