@import "./variables";
@import "./functions";

.checkbox-container {
    display: flex;
    align-items: center;
    gap: .5rem;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    text-align: left;
    flex-shrink: 0;

    &.disabled {
        cursor: initial;
    }

    > div {
        position: relative;
        border: 1px solid #ced3de;
        background-color: $white;
        border-radius: 5px;
        flex-shrink: 0;
        width: 20px;
        height: 20px;

        @media screen and (min-width: $screen-lg) {
            font-size: 22px;
        }

        &.no-label {
            width: 20px;
            height: 20px;
        }

        .my-checkbox {
            opacity: 0;
        }
    
        .checkmark:after {
            content: "";
            display: none;
        }
    
        .my-checkbox:checked + .checkmark:after {
            display: block;
            position: absolute;
        }
    
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
    
            @media screen and (min-width: $screen-lg) {
                top: 0;
            }
    
            &:after {
                top: 2px;
                left: 2px;
                right: 2px;
                bottom: 2px;
                width: 14px;
                height: 14px;
                background: url("../icons/tasks/icon-check.svg") no-repeat center center;
                background-size: contain;
            }
        }
    }

    .label-text {
        font-size: rem(12);
        font-family: $font-light;

        @media screen and (min-width: $screen-lg) {
            font-family: $font-primary;
        }
    }
}

.radio-container {
    display: flex;
    position: relative;
    cursor: pointer;
    user-select: none;
    text-align: left;
    height: 20px;
    width: 20px;
    font-size: 22px;

    &.disabled {
        cursor: initial;
    }

    .my-radio {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: none;
    }

    .checkmark:after {
        content: "";
        display: none;
    }

    .my-radio:checked + .checkmark:after {
        display: block;
        position: absolute;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $clear-grey;

        &:after {
            left: 3px;
            top: 3px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $primary;
        }
    }

    .label-text {
        font-size: rem(12);
        font-family: $font-light;
        padding-left: 30px;

        @media screen and (min-width: $screen-lg) {
            font-family: $font-primary;
        }
    }
}
