// Pagination

.pagination {
    width: 100%;
    padding: 20px 30px 0;
    font-size: 0.8125rem; // 13px
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: $screen-lg) {
        font-size: 0.875rem; // 14px
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    .pagination-per-page {
        width: 50%;
        text-align: right;
        
        @media screen and (min-width: $screen-lg) {
            width: auto;
            text-align: left;
        }
    }

    .pagination-meta {
        padding-right: 20px;
        text-align: center;
        width: 40%;
        margin-top: 15px;

        @media screen and (min-width: $screen-lg) {
            text-align: left;
            padding-right: 0;
            margin: 0;
            width: auto;
        }
    }

    &-select {
        font-size: 0.875rem; // 14px
        position: relative;
        width: 50%;

        @media screen and (min-width: $screen-lg) {
            width: auto;
        }

        select {
            border: 1px solid $very-clear-grey;
            height: 40px;
            width: 100px;
            padding: 0 10px;
            margin-left: 15px;  
            border-radius: 5px;        

            @media screen and (min-width: $screen-lg) {
                border: 0;
                height: auto;
                width: auto;
                margin: 0 40px 0 15px;
            }

            &:focus {
                outline: 0;
            }
        }
    }
}

.pagination-next,
.pagination-prev {
    line-height: 0;
    width: calc(25% / 2);
    height: 20px;
    background: none;
    margin: 15px 0 0 0;
    padding: 0;
    text-align: right;
    height: 20px!important;
    border-radius: inherit;
    box-shadow: none!important;
    padding: 0!important;
    min-width: 20px!important;
    
    @media screen and (min-width: $screen-lg) {
        width: 30px;
        margin: 0;
    }

    svg {
        vertical-align: middle;
        max-width: 100%;
    }

    &:disabled {
        opacity: 0.5;
    }

    &:not(:disabled):hover {
        svg {
            fill: $grey;
        }
    }

    &:focus {
        outline: 0;
    }
}

.pagination-prev {
    transform: rotate(180deg);

    @media screen and (min-width: $screen-lg) {
        margin-left: 30px;
        margin-right: 30px;
    }
}
