@import './variables';
@import './functions';

.dropdow-menu {
    position: absolute;
    z-index: 10;
    color: black;
    right: 30px;
    top: 10px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background-color: white;
    overflow: hidden;
    min-width: 150px;
    white-space: nowrap;

    .dropdown-menu__item {
        padding: 10px;
        > svg {
            fill: $clear-blue-grey;
        }
        &:hover {
            background-color: $clear-grey;
            color: white;
            > svg {
                fill: white;
            }
        }
    }
}
