@import "./_variables";
@import "./functions";

.preview-container {
    border-radius: rem(12);
    background: url("../../static/images/background-task.webp") center center no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    position: relative;
    padding: rem(30);
    transition: all 0.3s;
    cursor: pointer;
    user-select: none;

    @include media-min-width($screen-lg) {
        padding: rem(30) rem(40);
    }

    &.file-uploaded {
        cursor: default;
        display: flex;
        flex-direction: column;
        gap: rem(40);
        padding: rem(15);

        @include media-min-width(1320px) {
            align-items: flex-start;
            flex-direction: row;
            padding: rem(30) rem(40);
        }
    }

    @include media-min-width(1320px) {
        height: 645px;
    }

    .preview-container__box {
        height: 100%;
        width: 100%;
        pointer-events: none;
    }

    &__icon {
        display: none;
        svg {
            transition: all ease .5s;
        }

        @include media-min-width($screen-lg) {
            width: 320px;
            height: 320px;
            margin: 20px auto;
            border: 6px dashed white;
            border-radius: 30px;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-text {
            display: none;
            font-family: $font-light;

            @include media-min-width($screen-lg) {
                display: block;
                text-align: center;
                color: white;
                font-size: rem(16);
                margin: 0;
                font-style: italic;
            }
        }
    }

    &:hover {
        .preview-container__icon {
            svg {
                transform: scale(1.05);
            }
        }
    }

    // Watch out! position absolute party in here
    .drag-preview {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        padding: rem(34);
        border-radius: rem(24);
        background: rgba($main-texts, 0.8);
        pointer-events: none; // Can't interact with it
        user-select: none; // Can't select anything in it
        opacity: 0;
        // Uncomment "will-change" if animation is not smooth: https://developer.mozilla.org/en-US/docs/Web/CSS/will-change
        // will-change: opacity;
        transition: opacity 0.25s ease;
        z-index: 1;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__text {
            color: white;
            font-size: rem(22);
            font-style: italic;
            position: absolute;
            left: 50%;
            bottom: 50px;
            transform: translateX(-50%);
            margin: 0;
        }

        &:before {
            $padding: 70px;

            content: "";
            width: calc(100% - $padding);
            height: calc(100% - $padding);
            border: 5px dashed white;
            position: absolute;
            top: calc($padding / 2);
            left: calc($padding / 2);
            padding: rem(34);
            border-radius: rem(20);
            z-index: 0;
        }
    }

    &.dragging {
        .drag-preview {
            opacity: 1;
        }
    }

    .preview-container__file-btn {
        height: 30px;
        max-width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .preview-container__file-btn--hover {
        position: absolute;
        z-index: 1;
        bottom: 15px;
    }

    .preview-container__laius-box {
        display: flex;
        flex-direction: column;
    }

    .preview-container__laius {
        display: flex;
        flex-direction: column;
        color: $clear-blue-grey;
        font-size: rem(12);

        strong {
            color: $primary;
            text-decoration: underline;
        }

        p {
            font-size: rem(16);
            line-height: rem(20);
            margin-bottom: 0;
            color: $secondary;
            white-space: pre-line;
        }

        h2 {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: rem(22);
            line-height: 1;
            font-family: $font-semi-bold;
            color: $secondary;
            margin-bottom: .5rem;

            span {
                margin-left: rem(6);
                margin-right: rem(6);
                font-size: rem(22);
                font-family: $font-semi-bold;
                color: white;
            }

            svg {
                height: 30px;
                width: 30px;
                margin-right: rem(10);
            }
        }
    }

    .preview-button {
        .btn-tasks {
            margin: 20px auto 0;
        }

        @include media-min-width(1320px) {
            .btn-tasks {
                margin: 0;
            }
            position: absolute;
            bottom: 30px;
            right: 40px;
        }
    }

    .preview-container__list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-item {
            text-align: center;
            display: block;
            font-size: 10px;
            color: rgb(168, 169, 176);
            line-height: 18px;
        }
    }

    .preview-container__preview-file-container {
        width: 320px;
        text-align: center;
        border-radius: rem(20);
        position: relative;
        margin: auto;
        pointer-events: none;

        @include media-min-width(1320px) {
            margin: 0;
        }

        @include media-max-width($screen-mini) {
            width: 100%;
        }
    }

    .preview-container__preview-file-icon {
        position: absolute;
        top: calc(320px - (57px + 14px));
        left: 16px;
        z-index: 1;
        opacity: 0.7;
        pointer-events: none;
    }

    .preview-container__preview-file-text {
        font-size: rem(18);
        line-height: rem(24);
        color: white;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 320px;
        margin: rem(10) 0 0;
        overflow: hidden;

        @include media-min-width($screen-lg) {
            font-size: rem(20);
            line-height: rem(30);
        }

        span {
            display: block;
            text-align: center;
            font-size: rem(16);
            font-style: italic;
            color: white;
        }
    }

    .preview-container__preview-file-loading {
        position: absolute;
    }

    .preview-container__preview-file {
        position: relative;
        display: block;
        object-fit: cover;
        height: 320px;
        width: 100%;
        border: 5px dashed white;
        border-radius: rem(18);

        &:not(img):before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: rem(12);
            background: rgba(#f3f3f3, 0.6);
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            user-select: none;
        }
    }

    .preview-container__preview-dirty-file {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 16px;
        padding-top: 16px;
        position: relative;

        .preview-container__preview-dirty-file__icon-container {
            border-radius: 50%;
            border: solid 3px $primary;
            padding: 10px;

            > svg {
                fill: $primary;
            }
        }

        span {
            color: $primary;
            font-size: 12px;

            strong {
                font-family: $font-semi-bold;
            }
        }
    }
}

.filters-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: rem(16);

    .filters-container__step {
        width: 100%;
        padding: rem(15);
        border-radius: rem(10);
        border: 5px solid white;
        background: #f3f3f3;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        gap: 1rem;

        @include media-min-width($screen-lg) {
            padding: rem(30);
        }

        @include media-min-width(1320px) {
            width: calc(100% - 360px);
        }

        &-title {
            font-size: rem(22);
            line-height: 1;
            color: $secondary;
            margin-bottom: 0;

            display: flex;
            align-items: center;
            gap: 0 0.625rem;

            @include media-max-width($screen-mini) {
                font-size: rem(20);
            }

            svg {
                height: 30px;
                width: 30px;
            }
        }

        .row {
            @include media-max-width($screen-sm) {
                margin-right: calc(-0.25* var(--bs-gutter-x));
                margin-left: calc(-0.25* var(--bs-gutter-x));
            }
            > * {
                @include media-max-width($screen-sm) {
                    padding-right: calc(var(--bs-gutter-x)* 0.25);
                    padding-left: calc(var(--bs-gutter-x)* 0.25);
                }
            }
        }
    }

    // select blur setttings section
    .filter-container__step__blur-settings {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .5rem;

        &-title {
            font-size: rem(16);
            color: $secondary;
            margin-bottom: 0;
        }

        .filter-container__step__blur-settings__option-row {
            background-color: #f3f3f3;
            height: 100%;
            width: 100%;
            border: 2px solid $white;
            border-radius: rem(10);
            padding: rem(10);
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            @include media-min-width($screen-lg) {
                padding: rem(10) rem(20);
            }

            .filter-container__step__blur-settings__option-row_title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 0.5rem;
                @include media-min-width($screen-lg) {
                    gap: 0;
                }

                @include media-max-width($screen-mini) {
                    gap: 0.25rem;
                }

                svg {
                    @include media-max-width($screen-mini) {
                        width: 36px;
                    }
                }
            }

            &.checkbox-first {
                padding: 0;
                justify-content: flex-start;
                gap: 0;

                .filter-container__step__blur-settings__option-row_title {
                    @include media-min-width($screen-lg) {
                        gap: 1rem;
                    }
                }
            }

            &.blurtype {
                min-height: 130px;
                justify-content: center;
                align-items: flex-start;
            }

            &--activated {
                cursor: pointer;
            }

            &--disabled {
                pointer-events: none;
                opacity: 0.5 !important;
            }

            &--checked {
                background-color: white;
            }

            &.no-bg {
                background-color: transparent;
            }

            &.no-border {
                border: 0;
            }

            &.checkbox-first {
                min-height: 60px;

                .filter-container__step__blur-settings__option-row__checkbox {
                    order: -1;

                    @include media-min-width($screen-lg) {
                        width: auto !important;
                    }
                }

                .checkbox-container {
                    width: 20px;
                    height: 20px;

                    @include media-min-width($screen-lg) {
                        width: auto;
                    }
                }

                .filter-container__step__blur-settings__option-row__text {
                    justify-content: flex-start !important;
                    margin-left: 0;
                }
            }

            .filter-container__step__blur-settings__option-row__checkbox {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-shrink: 0;
            }

            @include media-max-width($screen-lg) {
                display: flex;
                margin-left: 0;

                > div {
                    margin-left: 0;
                }
                .filter-container__step__blur-settings__option-row__checkbox {
                    > label {
                        > span {
                            top: calc(50% - 10px);
                            left: -20px;
                        }
                    }
                    justify-content: center;
                }

                .filter-container__step__blur-settings__option-row__text {
                    justify-content: center !important;
                    margin-left: 0;
                }
            }

            .filter-container__step__blur-settings__option-row__information {
                flex-basis: inherit;
                width: 15px;
                height: 15px;
                border: 1px solid $clear-blue-grey;
                border-radius: 50%;
                color: $clear-blue-grey;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
            }

            .filter-container__step__blur-settings__option-row__text {
                font-size: 14px;
                user-select: none;
                gap: 0 8px;
            }
        }
    }
}

.modal-message {
    display: flex;
    flex-direction: column;
}