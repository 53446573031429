@import '../../../static/scss/variables';
@import '../../../static/scss/functions';

/*
    TASK CONTAINER
*/

.task-actions-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 10;

    .task-list {
        padding: 0;
        margin: 0;
        display: none;

        &.show {
            display: block;
        }

        li {
            position: relative;
            margin-bottom: 10px;
        }
    }
}
