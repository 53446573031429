@import "./variables.scss";

.switch {
	display: inline-block;
	height: 21px;
	position: relative;
	width: 42px;
}

.switch input {
	display: none!important;
}

.slider {
	background-color: #ccc;
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.4s;
}

.slider:before {
	background-color: #fff;
	bottom: 2px;
	content: "";
	height: 17px;
	left: 3px;
	position: absolute;
	transition: 0.4s;
	width: 17px;
}

input:checked + .slider {
	background-color: #66bb6a;
}

input:checked + .slider:before {
	transform: translateX(20px);
}

input:disabled + .slider {
	opacity: 0.5;
}

.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
