@import '../../static/scss/_variables.scss';

//TODO DELETE

.lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    animation: 0.4s fadeIn;
}

.lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
    background-color: $primary;
    top: 8px;
    left: 8px;
    animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
    background-color: $primary;
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
    background-color: $primary;
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
    background-color: $primary;
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
    background-color: $primary;
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
    background-color: $primary;
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
    background-color: $primary;
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
    background-color: $primary;
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
    background-color: $primary;
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
}
@keyframes lds-grid {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}
@keyframes fadeOut {
    0% {
        visibility: visible;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.rounded-loader-animated {
    animation: rotate 1s linear infinite;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
