.tip-container {
    position: relative;
    display: block;
    &:hover {
        .tip {
            opacity: 1;
        }
    }

    /* Tooltip content */
    .tip {
        width: max-content;
        position: absolute;
        background-color: #333;
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        z-index: 10;
        /* Adjust the distance from the element */
        bottom: 130%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        font-size: 12px;
    }
}
